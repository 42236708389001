.employee-dashboard {
    background-color: #FCF9FF;
}

.view-more {
    color: #013978;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-right: 10px;
}

.divide-line {
    border: 1px solid #EFF0F1;
    margin: 12px 0;
}

.vertical-line {
    border: 1px solid #EFF0F1;
    margin: 15px 0;
}

@media (min-width: 992px) {
    .vertical-line {
        display: block;
    }
}



.small-icon {
    background-color: #f5f7f9;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
}

.employee-dashboard-title {
    margin: 20px 0;
}

.card-required-margin {
    margin-top: 20px;
}

.employee-date-picker {
    .dotted-calender {
        .MuiStack-root {
            padding-top: 0 !important;
            background-color: #fff;

            &:hover {
                border-color: transparent;
                border: none !important;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
            border: 1px dashed #d2d4da !important;

        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
            border: 1px dashed #d2d4da !important;
        }
    }
}

.card_height_cust {
    max-height: 350px;
    min-height: 350px;
}

.employee-dashboard-card {
    background-color: #FFFFFF;
    border: 2px solid #EFF0F1;
    padding: 10px;
    border-radius: 4px;

    .dashboard-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dashboard-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 23px;
        }
    }
}


.employee-profile-section {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #EFF0F1;
    border-radius: 4px;
    padding: 15px;
    background-image: url(../../../assets/images/employeedashboard/bg-1.png), url(../../../assets/images/employeedashboard/bg-2.png);
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto;
    display: flex;
    gap: 40px;
    position: relative;

    .employee-profile-card {
        display: flex;
        align-items: center;
        gap: 30px;

        .employee-profile-img-wrapper {
            position: relative;

            img {
                width: 120px;
                height: 120px;
                object-fit: cover;
                border: 4px solid #F8F3FD;
            }

            .status-point {
                // background-color: #48BA6F;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 2px solid #F8F3FD;
                position: absolute;
                right: 4px;
                bottom: 16px;
            }

        }

        .employee-profile-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .employee-details-section-1 {
                display: flex;
                gap: 30px;

                .employee-information {
                    background-color: #DEE5EE;
                    border-radius: 14px;
                    padding: 10px 20px;
                    width: 320px;

                    h1 {
                        color: #000;
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 6px;
                    }

                    h4 {
                        color: #000;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }

                .status {
                    h6 {
                        color: white;
                        padding: 8px 16px;
                        border-radius: 19px;
                        font-size: 14px;
                        width: 120px;
                        text-align: center;
                    }
                }
            }

            @media(min-width: 768px) and (max-width: 992px) {
                .employee-details-section-1 {
                    gap: 15px;
                }
            }
        }

        .employee-details-section-2 {
            display: flex;
            margin-top: 10px;
            justify-content: space-between;

            .detail-title {
                font-size: 16px;
                font-weight: 300;
                padding-bottom: 10px;
                color: #000;
            }

            .detail-value {
                font-weight: 400;
                font-size: 16px;
                color: #000;
            }
        }


        .dash-box {
            border-right: 2px dashed #848484;
            margin-left: 40px;
            margin-right: 40px;
            height: 150px;
            opacity: 0.2;
        }

        @media (max-width: 1440px) {
            .dash-box {
                display: none;
            }
        }
    }

    @media(min-width: 768px) and (max-width: 992px) {
        .employee-profile-card {
            gap: 15px;
        }
    }

    .employee-personal-information-card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 36px;
        grid-row-gap: 30px;

        .employee-information-box {
            display: flex;
            align-items: baseline;
            gap: 10px;


            .employee-information-content {
                .employee-information-title {
                    color: #000;
                    font-weight: 300;
                    font-size: 14px;
                    padding-bottom: 8px;

                }

                .employee-information-value {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .service-book-btn {
                color: #FFFFFF;
                background-color: #013979;
                border-color: #013979;
                padding: 5px 10px;
                font-size: 14px;

                &:focus-visible {
                    box-shadow: none;
                }
            }
        }

    }

    .profile-basic {
        position: absolute;
        top: 5px;
        right: 10px;
    }
}

@media (max-width : 1289px) {
    .employee-profile-section {
        flex-wrap: wrap;
    }
}

.employee-dashboard-card-section {
    .appraisals-card {

        .appraisals-card-info {
            display: flex;
            justify-content: space-between;
            align-items: center
        }

        .appriasals-details-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .appriasals-box {
                background-color: #FFFFFF;
                display: flex;
                justify-content: space-around;
                align-items: center;
                border-radius: 10px;
                border: 2px dashed #EFF0F1;
                padding: 10px;

                .appraisals-contents {
                    .appriasals-title {
                        color: #000;
                        font-weight: 300;
                        font-size: 16px;
                        padding-bottom: 8px;
                    }

                    .appriasals-value {
                        color: #000;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .to-do-lis-card {
        .table-box {
            max-height: 300px;
            overflow-y: scroll;

            .table-header {
                position: sticky;
                top: 0;
            }

            /* Reduce the font size of table headings */
            thead th {
                color: #000;
                font-weight: 300;
                font-size: 14px;
            }

            /* Apply dashed border-bottom to all table rows inside <tbody> */
            tbody tr {
                border-bottom: 2px dashed #EFF0F1;
            }

            tbody .todo-title {
                font-weight: 400;
                font-size: 14px;
                color: #000;
                // max-width: 190px;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // overflow: hidden;
            }
        }
    }

    @media(min-width: 992px) {
        .performance-wrapper {
            display: flex;
            gap: 8px;
        }
    }

    .performance-yearly-card {

        .performance-yearly-card-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }

    .documents-card {
        .documents-card-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        .document-box {
            background-color: #FFFFFF;
            border-radius: 10px;
            padding: 20px;
            border: 1px solid #EFF0F1;
            margin: 6px 6px;

            .document-wrapper {
                display: flex;
                justify-content: space-between;
            }

            .document-title {
                font-weight: 400;
                font-size: 16px;
                color: #000;
                padding: 8px 0;
            }

            .document-value {
                font-size: 16px;
                font-weight: 300;
                padding-bottom: 10px;
                color: #000;
            }
        }
    }

    .asset-card {
        .asset-card-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        .assets-box {
            background-color: #FFFFFF;
            border: 1px solid #EFF0F1;
            border-radius: 6px;
            padding: 15px;
            margin: 6px 10px;
            min-height: 280px;
            max-height: 280px;

            .assets-box-heading-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .assets-detail-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 25px;

                    .assets-image-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;

                        &.mobile {
                            background-color: #FD3995;
                        }

                        &.camera {
                            background-color: #554AF5;
                        }

                        &.laptop {
                            background-color: #3B7080;
                        }
                    }

                    .asset-name-info {
                        .asset-heading {
                            font-weight: 400;
                            font-size: 16px;
                            color: #000;
                        }

                        .asset-status-value {
                            font-size: 16px;
                            font-weight: 300;
                            color: #000;

                            .working-status {
                                font-weight: 400;
                                font-size: 16px;
                                color: #0D9A0D;
                            }
                        }

                    }




                }
            }

            .asset-details-information {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 15px 0 15px;
                grid-column-gap: 10px;
                grid-row-gap: 20px;

                .asset-content {
                    .asset-title {
                        font-size: 16px;
                        font-weight: 300;
                        padding-bottom: 5px;
                        color: #000;
                    }

                    .asset-value {
                        font-weight: 400;
                        font-size: 16px;
                        color: #000;
                    }
                }
            }
        }
    }

    .signed-doc-card {
        .signed-doc-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        .signed-doc-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .signed-doc-box {
                display: flex;
                gap: 20px;
                background-color: #FFFFFF;
                border: 2px solid #EFF0F1;
                border-radius: 10px;
                padding: 15px;

                .signed-doc-detail-info {
                    .signed-doc-title {
                        padding-bottom: 8px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #000;
                    }

                    .signed-doc-value {
                        font-size: 16px;
                        font-weight: 300;
                        color: #000;
                    }
                }
            }
        }
    }

    .ticket-card {

        .ticket-tab-bg {
            border: 1px solid #c9c3c3;
            max-width: fit-content;
            border-radius: 50px;
        }

        .nav-pills .nav-link {
            color: #000;
            font-size: 14px;
            font-weight: 600;

            &.active {
                background-color: #013979;
                color: #fff;
                border-radius: 50px;
                font-size: 14px;
                font-weight: 600;
            }


        }

        .ticket-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        .ticket-box {
            background-color: #FFFFFF;
            border: 1px solid #EFF0F1;
            border-radius: 10px;
            padding: 8px;
            height: 220px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .heading-container {
                display: flex;
                justify-content: space-between;

                // .pending {
                //     color: #FFC107;
                //     border: 2px solid #FFC107;
                //     border-radius: 50px;
                //     padding: 5px 10px;
                // }

                // .resolve {
                //     color: #23970D;
                //     border: 2px solid #23970D;
                //     border-radius: 50px;
                //     padding: 5px 10px;
                // }
            }

            .calender-info {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #53545E;
                padding-top: 8px;
            }

            .creater-name {
                padding: 10px 0;
                color: #000;
                font-size: 16px;
                font-weight: 400;
            }


            .date-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .date {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                }
            }

        }
    }

    .ljp-card {
        .ljp-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        .ljp-details-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 270px;
            overflow-y: scroll;
        }

        .ljp-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            background-color: #FFFFFF;
            border: 2px dashed #EFF0F1;
            border-radius: 10px;
            padding: 10px;



            .ljp-wrapper {
                display: flex;
                gap: 15px;
                width: 145px;

                .job-title {
                    max-width: 145px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .ljp-heading {
                padding-bottom: 8px;
                font-size: 16px;
                font-weight: 300;
                color: #000;
            }



            .ljp-details {
                font-weight: 400;
                font-size: 16px;
                color: #000;

                &.approved {
                    color: #0D9A0D;
                }

                &.rejected {
                    color: #E70D0D;
                }
            }

        }
    }

}

.document-box {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #EFF0F1;
    margin: 6px 6px;

    .document-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .document-title {
        font-weight: 400;
        font-size: 16px;
        color: #000;
        padding: 8px 0;
    }
}


@media (min-width: 992px) {
    .t1 {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .at {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .py {
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .as {
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .pr {
        padding-right: 0;
    }
}

.modal-signed-doc-box {
    display: flex;
    gap: 20px;
    background-color: #FFFFFF;
    border: 2px solid #EFF0F1;
    border-radius: 10px;
    padding: 15px;

    .modal-signed-doc-detail-info {
        .modal-signed-doc-title {
            padding-bottom: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #000;
        }

        .modal-signed-doc-value {
            font-size: 16px;
            font-weight: 300;
            color: #000;
            max-width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}