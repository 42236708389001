.employee-recent-wrapper {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px 30px;
  margin-top: 20px;

  .recent-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-title-wrapper {
      background-color: #f5f7f9;
      width: 120px;
      padding: 1px 8px;
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      align-items: center;
      cursor: pointer;
      border: 1px solid #f0f3f5;

      .filter-title {
        color: #013979;
        font-size: 14px;
      }

      .filterIcon {
        width: 15px;
      }
    }
  }

  .recent-title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    padding-top: 10px;
    color: #000;
  }

  .grid-section {
    display: flex;
    gap: 10px;

    .grid {
      border: 1px solid #f0f3f5;
      padding: 3px 10px;
      border-radius: 5px;
      display: flex;
      gap: 5px;
      font-size: 12px;
      max-width: 170px;
      align-items: flex-start;
      line-height: 18px;
      cursor: pointer;
      transition: 0.3s;
      color: #000;

      &:hover {
        background-color: #f5f7f9;
        border: 1px solid #013979;
      }

      img {
        margin-top: 3px;
      }
    }

    .active {
      background-color: #f5f7f9;
      border: 1px solid #013979;
    }
  }
}

.employee-management {
  .listing-wrapper {
    .probation-wrapper {
      display: flex;
      gap: 20px;

      .probation-option {
        .form-check-label {
          color: #707070;
        }

        .form-check-inline {
          padding-top: 10px;

          .form-check-input {
            border: 1px solid #d2d4da;
            border-radius: 2px;

            &:focus {
              box-shadow: 0 0 0 3px rgb(178 181 185 / 25%);
            }
          }

          .form-check-input:checked {
            background-color: #698ab0;
            border-color: #698ab0;
          }
        }
      }

      .probation-duration {
        color: #707070;

        .css-1fdsijx-ValueContainer {
          min-width: 70px;
        }
      }

      .css-10lk5e0-control {
        background-color: #eeeeee63;
        border-color: #e9e5e563;
        border-style: dashed;
        min-height: 40px;
        margin-left: 3px;
      }

      .css-1dimb5e-singleValue {
        font-size: 14px;
      }

      .css-olqui2-singleValue {
        font-size: 14px;
      }
    }

    .add-more {
      img {
        width: 13px;
        margin-left: 5px;
      }
    }
  }

  .input-textarea {
    &::before {
      height: 100% !important;
    }
  }

  .address-label {
    display: flex;
    gap: 20px;

    .form-check-label {
      color: #707070;
    }

    .form-check-input {
      border: 1px solid #d2d4da;
      border-radius: 2px;

      &:focus {
        box-shadow: 0 0 0 3px rgb(178 181 185 / 25%);
      }
    }

    .form-check-input:checked {
      background-color: #698ab0;
      border-color: #698ab0;
    }
  }
}

// phone input common style starts

.phone-input {
  border: 1px dashed #d2d4da;
  min-height: 40px;
  padding-left: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  .PhoneInputInput {
    border: none;
    min-height: 40px;
    font-size: 14px;

    &:focus-visible {
      outline: none !important;
    }
  }

  .PhoneInputCountrySelectArrow {
    display: none;
  }

  .PhoneInputCountry {
    border-right: 1px dashed #d2d4da;
    padding-right: 10px;

    .PhoneInputCountrySelect {
      display: none;
    }
  }
}

.no-border {
  border: none;
}

.no-border:focus,
.no-border:active {
  outline: none;
}

// phone input common style end

// date range starts
.ant-picker-range {
  background-color: transparent;
  border: 1px dashed #d2d4da;
  min-height: 40px;
  border-left: none;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

:where(.css-dev-only-do-not-override-17sses9).ant-picker:hover {
  border-color: #d0d6dd;
}

// date range end

.file-upload-grp {
  display: flex;
  gap: 45px;
}

.ant-upload-drag-icon {
  .upload-icon-wrapper {
    img {
      margin: 0 auto;
    }
  }

  .upload-text {
    text-align: center;
    line-height: 16px;
    font-size: 13px;
  }
}

// newInput-modal start

.newInput-modal {
  .modal-header {
    border-bottom: none;
  }
}

// newInput-modal end

.view-recent {
  padding: 20px 30px;
  margin-bottom: -15px;
  margin-top: 20px;
}

.profile-details-wrapper {
  .img-wrapper {
    display: flex;
    align-items: center;

    img {
      border-radius: 8px;
    }

    .empid {
      font-size: 12px;
      font-weight: 400;
    }

    .dpt-head-details {
      margin-top: 10px;
      font-size: 13px;

      .dpt-head {
        font-weight: 600;
      }

      .head-name {
        font-weight: 400;
        color: #013979;
      }
    }

    .emp-img-wrapper {
      position: relative;

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000057;
        top: 0;
        border-radius: 8px;
        opacity: 0;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        .delete_icon {
          width: 18px !important;
          height: 18px !important;
          object-fit: contain;
          color: #fff;
          cursor: pointer;
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }
  }
}

.employee-management {
  .pt-0 {
    padding-top: 0;
  }

  .pb-50 {
    padding-bottom: 100px;
  }

  .view-section {
    padding-top: 0px;
    margin-bottom: -50px;
  }

  .profile-name {
    font-size: 14px !important;
  }

  .listing-wrapper {
    padding-top: 0;

    .contact-subtitle {
      font-size: 17px;
      padding-top: 40px;
      font-weight: 600;
      padding-bottom: 10px;
    }
  }
}

.addnew-seperation {
  margin: 40px 0px;
  color: #01397970;
  opacity: 1;
}

.deletebtn-wrapper {
  text-align: right;

  .btn-delete {
    background-color: transparent !important;
    border-color: #eb5959;
    padding: 5px 13px;
    border-radius: 5px !important;
  }
}

.department-management {
  .dataTable-header {
    padding: 10px 0px;
  }
}

.emp-photoEdit-modal {
  .filepond--credits {
    display: none;
  }

  .filepond--wrapper {
    margin-left: 3px;
    border-radius: 0;
  }

  .filepond--root {
    margin-bottom: 0 !important;
  }

  .filepond--panel-root {
    background-color: #ffffff !important;
    border-radius: 0 !important;
    border: 1px dashed #d2d4da;
    border-left: 0;
  }

  .input-border {
    width: 100% !important;
  }
}

.pad_style {
  .css-1xc3v61-indicatorContainer {
    padding: 6px !important;
  }
}