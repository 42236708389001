.add-promotion-section{
    .probation-wrapper{
        .probation-option{
            label.form-check-label{
                padding-top: 5px;
            }
        }
    }
    .listing-form{        
        .btn-groups{
            .question-btn.approve{
                background-color: #fbb616 !important;
                border-color: #fbb514 !important;
            }
        }
    }
}