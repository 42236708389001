.characteristics-grid{
    background-color: #F6F8FA;
    padding: 20px 40px;
    margin-bottom: 20px;
    border-radius: 5px;
    .section-title{
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 15px;
        color: #000;
    }
    .grid-sec{
        display: flex;
        gap: 40px;
        .form-check{
            cursor: pointer;
            label{
                font-size: 14px;
                font-weight: 400 !important;
                color: #53545E;
                cursor: pointer;
            }
            .form-check-input{
                width: 15px;
                height: 15px;
                margin-top: 5px;
                cursor: pointer;
                &:focus{
                box-shadow: 0 0 0 0.25rem rgb(91 112 143 / 25%);
                }
            }
        }
        .form-check-input:checked {
            background-color: #013979;
            border-color: #013979;
        }
    }
}


.appraisal-management{
    .dataTable-header{
        background-color: #F4F5F9;
    }
 .btn-resend{
    padding: 1px 5px;
    font-size: 14px !important;
    border: 1px solid #779be1;
    border-radius: 3px;
    background: #779be1;
    color: #fff !important;
 }
   
}


