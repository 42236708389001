.sidebar-section {
  height: calc(100vh - 61px);
  background-color: #013979;
  position: fixed;
  left: 0;
  width: 51px;
  top: 61px;
  text-align: center;
  z-index: 2;

  .MuiBox-root {
    height: 100vh;

    @media (max-height: 700px) {
      max-height: 800px;
      overflow-y: auto;
    }
  }

  .Mui-selected {
    background: #6a8bb0;
    border-radius: 10px;
  }

  .MuiTabs-indicator {
    background-color: #fbb616 !important;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .sidebar-menu-title {
    position: absolute;
    left: 50%;
    margin-left: -16px;
    z-index: 10;
  }

  .menu-title {
    color: #fff;
    font-size: 12px;
  }

  .MuiTabs-vertical {
    background-color: #013979;
    padding-top: 20px;
    z-index: 9;
  }

  .side-invisible {
    position: absolute;
    left: -270px;
    transition: all 0.3s ease 0s;
    width: 250px;
  }

  .tabpane-wrapper {
    position: absolute;
    right: -250px;
    width: 250px;
    background: #fff;
    color: #000;
    top: 0;
    height: calc(100vh - 61px);
    border-right: 2px solid #f8d685;
    text-align: left;
    z-index: 2;

    .MuiBox-root {
      padding: 0 !important;
    }

    .tab-title {
      color: #a7acb1;
      position: relative;
      font-size: 16px;
      border-bottom: 1px solid #f4f5f9;
      padding: 20px 10px 20px 20px;
      margin-bottom: 15px;
      z-index: 1;

      .next-wrapper {
        position: absolute;
        right: -17px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        top: 15px;
        background-color: #fff;
        box-shadow: 0px 0px 6px #dddada;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .pt-0,
    .nav-content {
      padding-top: 0;
    }

    .pt-0 {
      padding-bottom: 20px;
    }

    .nav-content {
      padding-top: 10px;

      .sublink {
        color: #6c757d;
        font-size: 14px;
        text-decoration: none;
        width: 100%;
      }

      li {
        padding-bottom: 10px;
        background-color: #f7f8fa;
        padding-left: 20px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        padding-right: 10px;

        .chevron-down {
          transition: transform 0.2s ease-in-out;
          fill: #6c7796;
        }
      }
    }

    .nav-head {
      display: flex;
    }

    .nav-link {
      color: #6c757d;
      font-size: 14px;
      padding-left: 20px;
      padding-right: 10px;

      .chevron-down {
        transition: transform 0.2s ease-in-out;
        fill: #6c7796;
      }
    }

    .nav-link:not(.collapsed) .chevron-down {
      transform: rotate(90deg);
    }

    .nav-link:not(.collapsed) {
      color: #013979;
    }
  }

  .user-profile {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    left: 50%;
    margin-left: -15px;

    .profile-icon {
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border: 1px solid #fff;
        border-radius: 5px;
      }
    }
  }

  .MuiButtonBase-root {
    min-width: 40px !important;
    min-height: 40px;

    img {
      width: 14px;
    }
  }

  .MuiTabs-flexContainer {
    gap: 10px;
    margin: 0px 5px;
  }
}

.logout-icon {
  cursor: pointer;
}

.candidate-info {
  .profile-img-wrapper {
    text-align: center;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .profile-details {
    text-align: center;
    padding-top: 8px;

    .profile-name {
      color: #013979;
      font-weight: 600;
      font-size: 18px;
    }

    .emailId {
      color: #363636;
      font-size: 13px;
      padding-bottom: 3px;
    }
  }
}

.counter-wrapper {
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -50px;

  .CircularProgressbar {
    width: 100px;
    height: 100px;

    .CircularProgressbar-trail {
      stroke: #f4f5f9;
    }

    .CircularProgressbar-path {
      stroke: #fab415;
      stroke-linecap: square;
    }

    .CircularProgressbar-text {
      fill: #bfbfc2;
      font-size: 16px;
    }
  }

  .time-label {
    position: absolute;
    bottom: 24px;
    left: 44px;
    font-size: 12px;
    color: #bfbfc2;
  }
}