#modal-reset-password {
  height: 45vh;
}

#modal-reset-password .content-wrapper {
  padding: unset;
}

#modal-reset-password .container-fluid-modal {
  width: 65%;
}

#modal-reset-password .error-password {
  font-size: 12px;
  display: block;
  margin-bottom: 15px;
  color: red;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media (min-width: 992px) {
    .container-fluid {
      margin-left: -15px;
    }
  }

  .reset-wrapper {
    display: flex;
    height: 100vh;

    @media (max-width: 991px) {
      align-items: center;
      justify-content: center;

      .form-sec {
        width: 400px;
        padding-top: 20px;
        margin: 0 auto;
      }
    }

    @media (max-width: 400px) {
      .form-sec {
        width: 300px;
        padding-top: 20px;
      }
    }

    .left-block {
      @media (min-width: 1199px) {
        width: calc(100% - 600px);
      }

      @media (min-width: 992px) and (min-width: 992px) {
        width: calc(100% - 550px);
      }

      @media (max-width: 991px) {
        display: none;
      }

      .img-wrapper {
        // background-image: url('../../images/home/reset-password.jpg');
        height: 100vh;
        width: 100%;
        object-fit: cover;

        img {
          height: 100vh;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    @media (min-width: 1199px) {
      .right-block {
        width: 580px;

        .form-sec {
          width: 300px;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      .right-block {
        width: 480px;

        .form-sec {
          width: 300px;
        }
      }
    }

    .right-block {
      display: flex;
      align-items: center;
      justify-content: center;

      .content-wrapper {
        margin-top: -60px;

        .img-wrapper {
          text-align: center;
          padding-bottom: 30px;

          img {
            width: 202px;
            height: 150px;
            object-fit: contain;
          }
        }

        p {
          font-size: 14px;
        }

        .forgot {
          font-size: 14px;
          text-decoration: none;
          color: #013979;
        }

        .zapareLogo {
          position: absolute;
          right: 30px;
          bottom: 9px;

          img {
            width: 200px;
            height: 50px;
            object-fit: contain;
          }

          span {
            font-size: 10px;
            text-align: left;
            color: #1f3a6e;
            font-weight: 400;
            margin-bottom: -13px;
          }
        }
      }
    }
  }

  .content-wrapper {
    @media (min-width: 992px) {
      padding: 90px;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #304658;
    }

    @media (min-width: 992px) {
      h3 {
        font-size: 34px;
      }

      p {
        padding: 15px 0px;
      }
    }

    .form-sec {
      .form-label {
        // border: 1px solid #bdd4dc;
        width: 100%;
        // padding: 15px 50px;
        border-radius: 7px;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        position: relative;
      }

      .login_inputs {
        input {
          background-color: #fff !important;

          &:focus {
            background-color: #fff !important;
          }
        }

        .invalid-feedback {
          font-size: 12px;
          margin-top: -10px;
          display: block;
          margin-bottom: 15px;
        }
      }

      .password-wrapper {
        position: relative;

        .invalid-feedback {
          margin-top: 0;
        }
      }

      .forgot-passowrd-title {
        text-align: center;

        .forgot-title {
          font-size: 28px;
          font-weight: 400;
          letter-spacing: 1px;
        }

        .forgot-sub-title {
          font-size: 12px;
          margin-top: -10px;
          color: #363636;
        }
      }

      .back-btn {
        text-align: center;
        padding: 15px 0px;
        font-size: 14px;
        color: #000;
        text-decoration: none;

        svg {
          height: 21px;
          width: 21px;
        }
      }

      input {
        border-color: transparent;
        padding: 2px 2px 2px 15px;
        font-size: 14px;
        margin-top: 0px;
        min-height: 40px;
        border: 1px solid #d1cbcb78;

        &:focus {
          box-shadow: none;
        }
      }

      .email-input {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .password-input {
        border-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .check {
        display: flex;
        justify-content: flex-end;

        input {
          border: 1px solid grey;
          width: 15px;
          height: 15px;
          margin-top: 4px;
          margin-right: 10px;
        }

        label {
          color: #00c0ff;
          font-size: 14px;
        }

        .forgot {
          color: #a3a8aa;
          font-size: 14px;

          img {
            width: 18px;
            height: 18px;
            margin-top: -4px;
          }
        }
      }

      .proceed-btn {
        background: #1f3a6e;
        border-color: #1f3a6e;
        padding: 5px 50px;
        width: 100%;
        border-radius: 6px;
        margin-top: 30px;
        color: #fbb514;

        &:focus {
          box-shadow: none;
        }
      }

      .form-check {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 15px 0px;
        padding-left: 1.5em;
        cursor: pointer;

        .form-check-input:checked {
          background-color: #1f3a6e;
          border-color: #1f3a6e !important;
        }
      }

      label {
        font-size: 14px;
        cursor: pointer;
      }

      .form-check-input {
        border: 1px solid #a1a1a1 !important;
        min-height: 15px;
        padding: 2px 2px 2px 0px;
        cursor: pointer;
      }
    }

    .group {
      text-align: center;

      .loader {
        text-align: center;
        margin-top: 40px;

        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    h3 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (min-width: 576px) and (max-width: 991px) {
    .footer-bg {
      height: 137px;
    }
  }

  @media (max-width: 575px) {
    .footer-bg {
      height: 90px;
    }
  }
}

.setpass {
  .content-wrapper {
    .password-toggle-btn {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #9797b1;
      position: absolute;
      right: 18px;
      top: 0px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}


.setpass_reset {
  .content-wrapper {
    .password-toggle-btn {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #9797b1;
      position: absolute;
      right: 8px;
      top: -4px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.login_inputs_reset {
  position: relative;


  input {
    border: 1px dashed #d2d4da;
    border-radius: 0px;
    position: relative;
    margin-left: 5px;
    font-size: 14px;
    color: hsl(0, 0%, 20%);
    background-color: #fff !important;

    &:focus {
      background-color: #fff !important;
      box-shadow: none;
    }
  }

  .invalid-feedback {
    font-size: 12px;
    margin-top: -10px;
    display: block;
    margin-bottom: 15px;
  }
}