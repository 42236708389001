.dataTable-wrapper {
  background-color: #fff;
  padding: 20px;

  .dataTable-title-wrap {
    .dataTable-title {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 20px;
      color: #000;
    }

    .green-dot,
    .orange-dot,
    .red-dot {
      display: inline-block;
      width: 9px;
      height: 9px;
      border-radius: 50px;
    }

    .green-dot {
      background-color: #00b11d;
    }

    .orange-dot {
      background-color: #ffbb00;
    }

    .red-dot {
      background-color: #e20000;
    }

    .asset-tags {
      .tag-label {
        font-size: 14px;
      }
    }
  }

  .table-wrapp {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: #f5f7f9;
        border-bottom: 0;
      }
    }

    .rdt_TableBody {
      .rdt_TableRow {
        min-height: 40px !important;
        border-bottom: 1px solid #f0f3f5;
      }
    }

    .rdt_Pagination {
      border-top-color: transparent;
    }

    .ellipse_email {
      font-size: 14px;
      color: #363636;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }

    .sc-eDVOW {
      font-weight: 700;
      font-size: 14px;
    }

    .custom-toggle-button {
      .form-check-input:checked {
        background-color: #013979;
        border-color: #013979;
      }

      .form-check-input:focus {
        box-shadow: 0 0 0 0.25rem rgb(1 57 121 / 14%);
      }
    }
  }

  .action-wrapper {
    display: flex;
    gap: 15px;

    a {
      color: #5c586d;
    }

    .eye-wrapper {
      background-color: #f5f7f9;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      svg {
        cursor: pointer;
      }
    }

    .eye-wrapper-blue {
      background-color: #013979;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      svg {
        cursor: pointer;
        color: #fff;
      }
    }

    .pdf-wrapper {
      width: 12px;
      height: 12px;
      object-fit: contain;
    }
  }

  .dataTable-header {
    display: flex;
    justify-content: space-between;
    background-color: #f4f5f9;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;

    input {
      &:focus {
        box-shadow: none;
      }
    }

    .dataTable-left {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      .show-entries {
        .entries-title {
          color: #848485;
          margin: 0px 10px;
          font-size: 14px;
        }

        .MuiSelect-select {
          padding: 5px 10px;
          font-size: 14px;
        }

        .MuiSvgIcon-root {
          display: none;
        }
      }

      .filter-title-wrapper {
        background-color: #fff;
        width: 120px;
        padding: 3px 8px;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;

        .filter-title {
          font-size: 14px;
        }

        .filterIcon {
          img {
            width: 15px;
          }
        }
      }

      .refresh_btn {
        .btn-refresh {
          background-color: #4273d3;
          border-color: #4273d3;
          padding: 4px 10px;
          display: flex;
          justify-content: space-between;
          gap: 8px;
          color: #fff;
          font-size: 14px;
          align-items: center;

          img {
            height: 15px;
            margin-top: -3px;
          }
        }
      }

      .cvv-download {
        background-color: transparent;
        padding: 0;
        border: none;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .show-entries {
        display: flex;

        select {
          &:focus {
            box-shadow: none;
          }
        }

        .form-select {
          max-height: 29px;
          padding: 5px 2.25rem 5px 0.75rem;
          font-size: 14px;
          line-height: 1;
          cursor: pointer;
        }
      }
    }

    .dataTable-right {
      .btn-addnew {
        background-color: #fbb616;
        border-color: #fbb514;
        padding: 4px 10px;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        height: 38px;
        min-width: 102px;
        color: #000;
        font-size: 14px;
        align-items: center;

        img {
          height: 15px;
          margin-top: -3px;
        }
      }
    }
  }

  .vertical-timeline-element-content {
    box-shadow: 0 8px 30px rgba(214, 214, 214, 0.78) !important;
    padding: 14px 14px !important;

    .vertical-timeline-element-title {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .vertical-timeline-element-text {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 6px;
    }

  }
}

.vertical-timeline-element-icon {
  width: 20px !important;
  height: 20px !important;
  left: 52% !important;
  margin-left: -34px !important;
}

.vertical-timeline-element-date {
  top: -12px !important;
}

// data listing styles starts

.listing-wrapper {
  .accordion {
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }

    .accordion-item {
      border: none;
      margin-bottom: 1px;

      .accordion-header {
        button {
          border-radius: 6px;
          padding: 12px 20px;
          z-index: 0;
        }
      }

      .accordion-header {
        button {
          background-color: #779be1;
          color: #fff;
          border-radius: 10px;
          font-weight: 500;
          position: relative;

          &:hover {
            border-color: #779be1;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed) {
            &::after {
              background-image: url("../../images/listing/minus.png");
              width: 16px;
              height: 16px;
              background-size: contain;
            }
          }

          &::after {
            background-image: url("../../images/listing/plus.png");
            width: 16px;
            height: 16px;
            background-size: contain;
          }
        }

        .btn-bulk {
          position: absolute;
          right: 70px;
          background-color: #fff;

          font-size: 14px;
          padding: 3px 10px;
          display: flex;
          align-items: center;
          gap: 7px;
          justify-content: center;

          &:focus {
            border: 1px solid #fff;
          }

          .bulk-text {
            color: #000;
          }

          .bulk_icon {
            width: 15px;
            fill: #000;
          }
        }
      }

      .listing-form {
        .mb-3 {
          margin-bottom: 25px !important;
        }

        .form-check {
          display: flex;
          align-items: center;
          gap: 5px;
          padding-left: 1.5em;
          cursor: pointer;

          .form-check-input {
            border: 1px solid #a1a1a1;
            min-height: 15px;
            padding: 2px 2px 2px 0px;
            cursor: pointer;
          }

          // .form-check-label {
          //   width: 70px;
          //   white-space: nowrap;
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          // }

          .form-check-input:checked {
            background-color: #1f3a6e;
            border-color: #1f3a6e !important;
          }

          .form-check-input:focus {
            box-shadow: none;
          }
        }

        .instructionLable {
          display: flex;
        }

        label {
          font-size: 14px;
          font-weight: 700;
          color: #000;

          .required {
            color: red;
          }
        }

        .warning_icon {
          img {
            width: 13px;
            margin-left: 5px;
            margin-top: -3px;
          }
        }

        .dotted-input-half {
          border: 1px dashed #d2d4da;
          border-radius: 0;
          position: relative;
          border-left-color: #fff;
          margin-left: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          font-size: 14px;
          color: hsl(0, 0%, 20%);

          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            font-size: 14px;
            color: #696a72;
          }
        }

        .input-border {
          position: relative;

          &:before {
            position: absolute;
            content: "";
            height: 40px;
            width: 3px;
            background-color: #698ab0;
            left: 0;
            top: 0;
          }
        }

        .option-wrapper {
          display: flex;
          gap: 20px;
          align-items: center;
          justify-content: center;
          margin-top: 10px;

          .form-check-input {
            &:focus {
              box-shadow: 0 0 0 0.25rem rgb(1 57 121 / 16%);
            }

            &:checked {
              background-color: #013979;
              border-color: #485c73;
            }
          }
        }

        .add-more-wrapper {
          .add_more {
            color: #013979;
            font-size: 14px;
            display: flex;
            gap: 10px;
            align-items: center;
            // cursor: pointer;
            margin-bottom: 15px;

            img {
              width: 15px;
              margin-top: -4px;
            }

            span {
              cursor: pointer;
            }
          }
        }

        .other-lebel {
          color: #013979;
        }

        .other-input {
          border: 1px dashed #698ab0;
          border-left: 0;
        }

        .css-1dimb5e-singleValue {
          font-size: 14px;
        }

        .address-title {
          font-size: 20px;
          padding-bottom: 30px;
          color: #013979;
        }

        .residential-address-title {
          padding-bottom: 30px;
          display: flex;
          gap: 20px;
          align-items: center;

          h3 {
            font-size: 20px;
            color: #013979;
            padding-bottom: 10px;
          }

          .form-check-label {
            font-weight: 500 !important;
          }

          .form-check-input {
            border: 1px solid #d2d4da;
            border-radius: 2px;
            cursor: pointer;

            &:focus {
              box-shadow: 0 0 0 3px rgba(178, 181, 185, 0.25);
            }
          }

          .form-check-input:checked {
            background-color: #698ab0;
            border-color: #698ab0;
          }
        }
      }

      .bg-wrapper {
        border-radius: 0;

        .single-question {
          position: relative;
          background-color: #f6f8fa;
          margin-bottom: 20px;
          padding: 20px;
          border-radius: 4px;

          .question_choice {
            display: flex;
            list-style: none;
            gap: 20px;

            .count {
              width: 25px;
              text-align: center;
              font-size: 14px;
              padding-top: 8px;
              font-weight: 600;
            }

            .question-input {
              width: 70%;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 15px 0px;
                padding-left: 1.5em;
                cursor: pointer;

                .form-check-input {
                  border: 1px solid #a1a1a1 !important;
                  min-height: 15px;
                  padding: 2px 2px 2px 0px;
                  cursor: pointer;
                }

                .form-check-input:checked {
                  background-color: #1f3a6e;
                  border-color: #1f3a6e !important;
                }

                .form-check-input:focus {
                  box-shadow: none;
                }
              }
            }

            .question-input-instruction {
              width: 100%;
            }

            .select-question {
              width: 20%;

              .MuiInputBase-root {
                width: 100%;
                border-radius: 0;

                .MuiSelect-select {
                  padding: 0.26rem 0.74rem;
                  border: 1px dashed #d2d4da;
                  border-radius: 0;
                  display: flex;
                  gap: 10px;
                  color: #717171;
                  font-size: 14px;

                  em {
                    font-size: 14px;
                    font-style: normal;
                  }
                }
              }
            }
          }

          .options {
            padding-left: 55px;

            label {
              color: #717171;
              font-size: 14px;
            }

            .opt {
              display: flex;
              gap: 10px;
              align-items: center;
              margin-bottom: 10px;

              .delete-wrapper {
                cursor: pointer;
                width: 20px;
                height: 20px;
                background: #fff9f9;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
              }
            }

            .form-check-input:checked {
              background-color: #00b11d;
              border-color: #00b11d;
              width: 14px;
              height: 14px;
            }

            .form-check-input:focus {
              box-shadow: 0 0 0 0.25rem #00b11d29;
            }
          }

          .delete-sec {
            cursor: pointer;
            font-size: 13px;
            display: flex;
            gap: 10px;
            background-color: #fff;
            // position: absolute;
            // right: 40px;
            // bottom: 20px;
            padding: 2px 7px;
            border-radius: 5px;
            width: fit-content;
          }

          .add-option {
            margin-top: 15px;
            padding-left: 55px;
            max-width: 400px;

            // cursor: pointer;
            .form-check {
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 15px 0px;
              padding-left: 1.5em;
              cursor: pointer;

              .form-check-input {
                border: 1px solid #a1a1a1 !important;
                min-height: 15px;
                padding: 2px 2px 2px 0px;
                cursor: pointer;
              }

              .form-check-input:checked {
                background-color: #1f3a6e;
                border-color: #1f3a6e !important;
              }

              .form-check-input:focus {
                box-shadow: none;
              }
            }

            p {
              font-size: 14px;
              color: #717171;

              span {
                cursor: pointer;
              }

              .add_icon {
                img {
                  width: 15px;
                  margin-right: 12px;
                  margin-top: -4px;
                }
              }

              .text-bold {
                color: #013979;
              }
            }
          }
        }

        .add-more-wrapper {
          .add_more {
            color: #013979;
            font-size: 14px;
            display: flex;
            gap: 10px;
            align-items: center;
            // cursor: pointer;
            margin-bottom: 15px;

            span {
              cursor: pointer;
            }

            img {
              width: 15px;
              margin-top: -4px;
            }
          }
        }
      }
    }
  }

  .dotted-input {
    border: 1px dashed #d2d4da;
    border-radius: 5px;
    font-size: 14px;
    color: #303034;
    // background-color: #fff;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: 14px;
    }
  }
}

// data listing styles end

.listing-wrapper-custom {
  .accordion {
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
      padding-top: 6px;
    }

    .accordion-item {
      border: none;
      margin-bottom: 1px;

      .accordion-header {
        button {
          border-radius: 6px;
          padding: 12px 20px;
          z-index: 0;
        }
      }

      .accordion-header {
        button {
          background-color: #013979;
          color: #fff;
          border-radius: 10px;
          font-weight: 500;
          position: relative;

          &:hover {
            border-color: #013979;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed) {
            &::after {
              background-image: url("../../images/listing/minus.png");
              width: 16px;
              height: 16px;
              background-size: contain;
            }
          }

          &::after {
            background-image: url("../../images/listing/plus.png");
            width: 16px;
            height: 16px;
            background-size: contain;
          }
        }

        .btn-bulk {
          position: absolute;
          right: 70px;
          background-color: #fff;

          font-size: 14px;
          padding: 3px 10px;
          display: flex;
          align-items: center;
          gap: 7px;
          justify-content: center;

          &:focus {
            border: 1px solid #fff;
          }

          .bulk-text {
            color: #000;
          }

          .bulk_icon {
            width: 15px;
            fill: #000;
          }
        }
      }

      .listing-form {
        .mb-3 {
          margin-bottom: 25px !important;
        }

        .form-check {
          display: flex;
          align-items: center;
          gap: 5px;
          padding-left: 1.5em;
          cursor: pointer;

          .form-check-input {
            border: 1px solid #a1a1a1;
            min-height: 15px;
            padding: 2px 2px 2px 0px;
            cursor: pointer;
          }

          // .form-check-label {
          //   width: 70px;
          //   white-space: nowrap;
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          // }

          .form-check-input:checked {
            background-color: #1f3a6e;
            border-color: #1f3a6e !important;
          }

          .form-check-input:focus {
            box-shadow: none;
          }
        }

        .instructionLable {
          display: flex;
        }

        label {
          font-size: 14px;
          font-weight: 700;
          color: #000;

          .required {
            color: red;
          }
        }

        .warning_icon {
          img {
            width: 13px;
            margin-left: 5px;
            margin-top: -3px;
          }
        }

        .dotted-input-half {
          border: 1px dashed #d2d4da;
          border-radius: 0;
          position: relative;
          border-left-color: #fff;
          margin-left: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          font-size: 14px;
          color: hsl(0, 0%, 20%);

          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            font-size: 14px;
            color: #696a72;
          }
        }

        .disabled-input {
          background-color: #f0f0f0;
          color: #013979;
          font-weight: 600;
        }

        .input-border {
          position: relative;

          &:before {
            position: absolute;
            content: "";
            height: 40px;
            width: 3px;
            background-color: #698ab0;
            left: 0;
            top: 0;
          }
        }

        .option-wrapper {
          display: flex;
          gap: 20px;
          align-items: center;
          justify-content: center;
          margin-top: 10px;

          .form-check-input {
            &:focus {
              box-shadow: 0 0 0 0.25rem rgb(1 57 121 / 16%);
            }

            &:checked {
              background-color: #013979;
              border-color: #485c73;
            }
          }
        }

        .add-more-wrapper {
          .add_more {
            color: #013979;
            font-size: 14px;
            display: flex;
            gap: 10px;
            align-items: center;
            // cursor: pointer;
            margin-bottom: 15px;

            img {
              width: 15px;
              margin-top: -4px;
            }

            span {
              cursor: pointer;
            }
          }
        }

        .other-lebel {
          color: #013979;
        }

        .other-input {
          border: 1px dashed #698ab0;
          border-left: 0;
        }

        .css-1dimb5e-singleValue {
          font-size: 14px;
        }

        .address-title {
          font-size: 20px;
          padding-bottom: 30px;
          color: #013979;
        }

        .residential-address-title {
          padding-bottom: 30px;
          display: flex;
          gap: 20px;
          align-items: center;

          h3 {
            font-size: 20px;
            color: #013979;
            padding-bottom: 10px;
          }

          .form-check-label {
            font-weight: 500 !important;
          }

          .form-check-input {
            border: 1px solid #d2d4da;
            border-radius: 2px;
            cursor: pointer;

            &:focus {
              box-shadow: 0 0 0 3px rgba(178, 181, 185, 0.25);
            }
          }

          .form-check-input:checked {
            background-color: #698ab0;
            border-color: #698ab0;
          }
        }
      }

      .bg-wrapper {
        border-radius: 0;

        .single-question {
          position: relative;
          background-color: #f6f8fa;
          margin-bottom: 20px;
          padding: 20px;
          border-radius: 4px;

          .question_choice {
            display: flex;
            list-style: none;
            gap: 20px;

            .count {
              width: 25px;
              text-align: center;
              font-size: 14px;
              padding-top: 8px;
              font-weight: 600;
            }

            .question-input {
              width: 70%;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 15px 0px;
                padding-left: 1.5em;
                cursor: pointer;

                .form-check-input {
                  border: 1px solid #a1a1a1 !important;
                  min-height: 15px;
                  padding: 2px 2px 2px 0px;
                  cursor: pointer;
                }

                .form-check-input:checked {
                  background-color: #1f3a6e;
                  border-color: #1f3a6e !important;
                }

                .form-check-input:focus {
                  box-shadow: none;
                }
              }
            }

            .question-input-instruction {
              width: 100%;
            }

            .select-question {
              width: 20%;

              .MuiInputBase-root {
                width: 100%;
                border-radius: 0;

                .MuiSelect-select {
                  padding: 0.26rem 0.74rem;
                  border: 1px dashed #d2d4da;
                  border-radius: 0;
                  display: flex;
                  gap: 10px;
                  color: #717171;
                  font-size: 14px;

                  em {
                    font-size: 14px;
                    font-style: normal;
                  }
                }
              }
            }
          }

          .options {
            padding-left: 55px;

            label {
              color: #717171;
              font-size: 14px;
            }

            .opt {
              display: flex;
              gap: 10px;
              align-items: center;
              margin-bottom: 10px;

              .delete-wrapper {
                cursor: pointer;
                width: 20px;
                height: 20px;
                background: #fff9f9;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
              }
            }

            .form-check-input:checked {
              background-color: #00b11d;
              border-color: #00b11d;
              width: 14px;
              height: 14px;
            }

            .form-check-input:focus {
              box-shadow: 0 0 0 0.25rem #00b11d29;
            }
          }

          .delete-sec {
            cursor: pointer;
            font-size: 13px;
            display: flex;
            gap: 10px;
            background-color: #fff;
            // position: absolute;
            // right: 40px;
            // bottom: 20px;
            padding: 2px 7px;
            border-radius: 5px;
            width: fit-content;
          }

          .add-option {
            margin-top: 15px;
            padding-left: 55px;
            max-width: 400px;

            // cursor: pointer;
            .form-check {
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 15px 0px;
              padding-left: 1.5em;
              cursor: pointer;

              .form-check-input {
                border: 1px solid #a1a1a1 !important;
                min-height: 15px;
                padding: 2px 2px 2px 0px;
                cursor: pointer;
              }

              .form-check-input:checked {
                background-color: #1f3a6e;
                border-color: #1f3a6e !important;
              }

              .form-check-input:focus {
                box-shadow: none;
              }
            }

            p {
              font-size: 14px;
              color: #717171;

              span {
                cursor: pointer;
              }

              .add_icon {
                img {
                  width: 15px;
                  margin-right: 12px;
                  margin-top: -4px;
                }
              }

              .text-bold {
                color: #013979;
              }
            }
          }
        }

        .add-more-wrapper {
          .add_more {
            color: #013979;
            font-size: 14px;
            display: flex;
            gap: 10px;
            align-items: center;
            // cursor: pointer;
            margin-bottom: 15px;

            span {
              cursor: pointer;
            }

            img {
              width: 15px;
              margin-top: -4px;
            }
          }
        }
      }
    }
  }

  .dotted-input {
    border: 1px dashed #d2d4da;
    border-radius: 5px;
    font-size: 14px;
    color: #303034;
    // background-color: #fff;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: 14px;
    }
  }
}

.MuiPopover-root {
  .MuiMenuItem-root {
    border-bottom: 1px dashed #c7c1c1;
    gap: 10px;
    font-size: 14px;
    font-size: 14px;
    font-family: "Lato", sans-serif !important;
    color: #717171;
  }
}

.css-t3ipsp-control {
  box-shadow: none !important;
}

.btn-groups {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;

  .btn {
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 5px !important;
    min-width: 110px !important;
  }

  .delete-btn {
    color: #f3234d !important;
    background-color: #f9eef1 !important;
    border-color: #f9eef1 !important;
  }

  .draft-btn {
    color: #4273d3 !important;
    border-color: #4273d3 !important;
    background-color: #fff !important;
  }

  .draft-btn-bg-remove {
    background-color: transparent !important;
  }

  .question-btn {
    background-color: #4273d3 !important;
    border-color: #779be1 !important;
    color: #fff !important;
  }

  .updation-btn {
    background-color: #ffb633 !important;
    border-color: #ffb633 !important;
    color: #fff !important;
  }

  .question-btn-custom-yellow {
    background-color: #FDB712 !important;
    border-color: #FDB712 !important;
    color: #fff !important;
  }

  .question-btn-custom-blue {
    background-color: #013979 !important;
    border-color: #013979 !important;
    color: #fff !important;
  }

  .question-btn-custom {
    background-color: #d34242 !important;
    border-color: #d34242 !important;
    color: #fff !important;
  }

  .save-btn {
    color: #f3f3f3 !important;
    border-color: #013979 !important;
    background-color: #013979 !important;
  }

  .save-btn-yellow {
    color: #000 !important;
    border-color: #fbb514 !important;
    background-color: #fbb616 !important;
  }

  .btn-view-all {
    background-color: #fbb616;
    border-color: #fbb514;
    padding: 4px 10px;
    color: #000;
    font-size: 14px;
    align-items: center;

    &:active {
      background-color: #fbb616;
      border-color: #fbb514;
      color: #000;
    }
  }

  .save-btn-role {}
}

.btn-groups-attributes {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 40px;

  .btn_attribute_right {
    display: flex;
    gap: 12px;
  }

  .btn {
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 5px !important;
    min-width: 110px !important;
  }

  .delete-btn {
    color: #f3234d !important;
    background-color: #f9eef1 !important;
    border-color: #f9eef1 !important;
  }

  .draft-btn {
    color: #4273d3 !important;
    border-color: #4273d3 !important;
    background-color: #fff !important;
  }

  .question-btn {
    background-color: #4273d3 !important;
    border-color: #779be1 !important;
    color: #fff !important;
  }


  .updation-btn {
    background-color: #ffb633 !important;
    border-color: #ffb633 !important;
    color: #fff !important;
  }

  .question-btn-custom {
    background-color: #d34242 !important;
    border-color: #d34242 !important;
    color: #fff !important;
  }

  .save-btn {
    color: #f3f3f3 !important;
    border-color: #013979 !important;
    background-color: #013979 !important;
  }

  .save-btn-role {}
}

// filter popup
.filter-popup {
  width: 300px !important;

  .filter-open-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;

    .seperate-reset-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      .reset-btn {
        min-height: 40px;
        background-color: #013979;
        border-color: #013979;
      }
    }

    label {
      font-size: 14px;
    }

    .mb-3 {
      margin-bottom: 25px !important;
    }
  }

  .btn-close {
    width: 9px;
    height: 9px;
    font-size: 9px;
    background-color: #e7e9eb;
    fill: #000;

    &:focus {
      box-shadow: none;
    }
  }

  .offcanvas-title {
    font-weight: 700;
  }

  .filter-btn-grp {
    display: flex;
    gap: 10px;
    justify-content: center;

    .btn {
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 5px !important;
      min-width: 130px !important;
    }

    .filter-btn {
      background-color: #4273d3 !important;
      border-color: #779be1 !important;
      color: #fff !important;
    }

    .reset-btn {
      color: #779be1 !important;
      border-color: #779be1 !important;
      background-color: #fff !important;
    }
  }
}

.css-1dimb5e-singleValue {
  font-size: 14px;
}

.file-upload-group {
  .ant-upload-wrapper {
    position: relative;

    .ant-upload-list {
      position: absolute;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 140px;
      font-size: 12px;

      .ant-upload-list-item-name {
        font-size: 12px;
        padding: 0 3px !important;
        color: green;
      }

      .ant-upload-list-item-done {
        height: 20px !important;
      }
    }
  }
}

.myassets-list {
  .dataTable-title-wrap {
    display: flex;
    justify-content: space-between;

    .asset-tags {
      .tag-label {
        padding-left: 5px;
        padding-right: 20px;
      }
    }
  }
}

.sc-dmcuvF {
  z-index: 0;
}

.new-client-tag {
  width: 13px;
  height: 13px;
  object-fit: contain;
  cursor: pointer;
}

.file-table-style {
  .ellipse_email {
    text-transform: capitalize;
  }
}

.no-accordion {
  .accordion .accordion-item .accordion-header button:not(.collapsed)::after {
    background-image: none !important;
  }

  .accordion .accordion-item .accordion-header button::after {
    background-image: none !important;
  }

  .accordion_button {
    background-color: #4273d3;
    color: #fff;
    border-radius: 10px;
    font-weight: 500;
    position: relative;
    width: 100%;
    text-align: left;
    padding: 12px 20px;
    border-color: #4273d3;
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }
}

.customDistrict-input {
  border: 1px dashed #d2d4da;
  border-radius: 0;
  position: relative;
  border-left-color: #fff;
  margin-left: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  color: #696a72;
  height: 40px;

  &:focus {
    box-shadow: none;
    border: 1px dashed #d2d4da;
    border-left: none;
    border-left-color: #fff;
  }
}

// leave calendar styles start

.leaveCalendar {
  .date-picker-group .MuiInputBase-root {
    min-width: 100% !important;
  }

  .leaveCalendar-wrapper {
    background-color: #f6f8fa;
    padding: 30px;
    margin-bottom: 20px;

    .add-option {
      margin-top: 15px;
      // cursor: pointer;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 14px;
        color: #717171;

        .add_icon {
          img {
            width: 15px;
            margin-right: 12px;
            margin-top: -4px;
          }
        }

        .text-bold {
          color: #013979;
        }
      }
    }
  }
}

// leave calendar styles end


.upcoming-birthday-section {
  .slick-track {
    height: auto !important;
  }

  .birthWrapper {
    padding-bottom: 20px;
  }
}

.dashboard-grid {
  .action-wrapper {
    .eye-wrapper {
      color: #000;
    }
  }
}

.upcoming-section {
  .ellipse_email {
    font-size: 14px;
    color: #363636;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}


.view-section {
  .input-border {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-color: #698ab0;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
    }
  }
}

.custom-toggle-button-view-all {
  .form-check-input:checked {
    background-color: #013979;
    border-color: #013979;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(1 57 121 / 14%);
  }
}