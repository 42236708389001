.employee-attendance-logs {
    .dataTable-wrapper.candidate-info {
        .dataTable-header {
            margin-bottom: 0px;
            padding-bottom: 0px;
            padding-top: 9px;
        }
    }

    .employee-attendance-logs-table {
        .ellipse_email {
            font-size: 14px;
        }
    }

    .fc {
        background: white;
        margin-top: 25px;

        .fc-daygrid-day-bottom {
            margin-top: 0px;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            z-index: 10;
        }

        .fc-highlight {
            // border: 3px solid #86d0f0;
            // border-bottom: 1px solid #86d0f0;
            margin: 1.0px 0px;
            // margin-bottom: 0px;
            z-index: 6;
            // background: transparent;
        }

        .fc-toolbar {

            .fc-icon-chevron-left,
            .fc-icon-chevron-right {
                &::before {
                    color: rgba(33, 37, 41, 0.7019607843);
                }
            }
        }

        .fc-toolbar.fc-header-toolbar {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .fc-toolbar-title {
            font-size: 22px;
        }

        .fc-header-toolbar {
            .fc-button-primary {
                background-color: transparent;
                border: none;
                box-shadow: none;

                &:focus {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .fc-dayGridMonth-view {
            table {
                thead {
                    .fc-scrollgrid-sync-inner {
                        a {
                            color: #212529b3;
                            text-decoration: auto;
                            font-weight: 600;
                        }
                    }
                }
            }

            .fc-daygrid-day-top {
                flex-direction: row;

                .fc-daygrid-day-number {
                    color: #212529;
                    text-decoration: auto;
                    font-weight: 600;
                }
            }

            .fc-daygrid-body-unbalanced {
                .fc-daygrid-day-events {
                    min-height: 5em;
                }
            }

            .fc-daygrid-body {
                .fc-daygrid-dot-event {
                    &:hover {
                        background: transparent;
                    }
                }

                .fc-event-main,
                .fc-event-today {
                    text-align: center;
                    min-height: 5.8em;

                    div.title {
                        min-height: 2.45rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        font-weight: 600;
                    }

                    div.shift {
                        position: absolute;
                        bottom: 0;
                        right: 2%;
                        font-size: 15px;
                        font-weight: 400;
                    }

                    i {
                        font-size: 15px;
                        font-style: normal;
                        margin: auto;
                        font-weight: 600;
                    }
                }

                .fc-event-today {

                    .title,
                    .shift {
                        color: rgba(0, 0, 0, 0.53);
                    }

                    // .title{
                    //     min-height: 3.6rem !important;
                    //     align-items: baseline !important; 
                    //     width: 100% !important;
                    // }                   
                }

                // td:has(.attendance-date) {
                //     // background-color: #edfaed;
                // }
                // td:has(.offday){
                //     // background-color: #f7f7f7;                    
                // }

                // /* Style the anchor tag inside the .fc-daygrid-day-top (day number) */
                // td:has(.offday) .fc-daygrid-day-top a.fc-daygrid-day-number {
                //     background-color: #f7f7f7;
                // }

                // /* Style the event container within the day cell */
                // td:has(.offday) .fc-daygrid-event.fc-event-end,
                // td:has(.offday) .fc-daygrid-event.fc-event-start {
                //     background-color: #f7f7f7 !important;
                // }

                // /* Optional: further styling for anchor tags inside events */
                // td:has(.offday) .fc-daygrid-event a {
                //     background-color: #f7f7f7 !important;
                // }
            }
        }
    }
}


.employeeAttendanceList {
    .dataTable-header {
        padding: 8px 0px;
    }

    .dataTable-wrapper {
        input#my-attendance {
            // border: 1px solid #a1a1a1 !important;
            min-height: 15px;
            padding: 2px 2px 2px 0px;
            cursor: pointer;
            border-radius: 2px;
        }

        .dataTable-left.w-85 {
            width: 87%;
        }

        .dataTable-left.w-100 {
            width: 100%;
        }
    }
}

.employeeAttendanceTable {
    // max-height: 422px;
    // min-height: 422px;
    max-height: 250px;
    min-height: 250px;

    .grid-title-wrapper {
        padding-bottom: 2px;

        .th-reload {
            align-items: baseline;
            min-width: 103px;
        }

        .th-reload.no-data {
            align-items: baseline;
            min-width: 80px;
        }

        .reload {
            svg {
                cursor: pointer;
            }
        }

        .sub-title {
            font-size: 14px;
            color: #013978;
            margin-right: 10px;
            font-weight: 700;
            line-height: 20px;
        }
    }

    .table-box {
        .table-header{
            position: sticky;
            top: 0;
        }
        table {
            td {
                font-size: 11px;
                padding: 5px;
            }

            thead {
                tr {
                    line-height: 0.9;
                }

                th {
                    font-size: 12px;
                }
            }
        }
    }

    .whrs-txt {
        font-size: 14px;
        padding-right: 9px;

        .whrs {
            font-size: 14px;
        }
    }

    .grid-title-wrapper {
        padding-bottom: 2px;
    }

    .table-box {
        overflow-y: scroll;
        max-height: 200px;

        .subtitle {
            font-size: 14px;
            color: #727272;
            font-weight: 400;
            margin-bottom: 4px;
        }
    }

    table {

        width: 100%;
        margin-bottom: 0px;

        thead {
            border-top: 1px solid #dfe2e6;

            th {
                background-color: #013978;
                color: #ffff;
            }
        }

        tbody {
            tr {
                &:nth-of-type(odd) {
                    * {
                        --bs-table-bg-type: #ffff;
                    }
                }
            }
        }
    }
}

.employeeAttendanceTable.holiday-list {
    .table-box {
        overflow: auto;

        .holiday-item {
            background-color: #f5f6fa;
            padding: 5px 10px;

            .title {
                font-size: 14px;
                font-weight: 600;
            }

            .day {
                font-size: 12px;
            }
        }
    }
}