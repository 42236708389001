.assign-shift {
  .assign-wrapper {
    margin-top: 50px;
  }

  .shift-export {
    box-shadow: 0px 1px 4px 0px #0000001F;
    padding: 0px 30px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
  }

  .shift-refresh {
    box-shadow: 0px 1px 4px 0px #0000001F;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: #0000008a;
  }

  .shift-paginate {
    box-shadow: 0px 1px 4px 0px #0000001F;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    color: #0000008a;
  }

  .shift-table-main-head {
    background-color: #6297B0;
    color: #3D3D3D;
    border-right: 1px solid #ffff;

    &:before {
      background-color: transparent !important;
    }
  }

  .shift-table-sub-head {
    background-color: #E4E9EF;
    color: #424242;
    border-right: 1px solid #DFDFDF;

    &:before {
      background-color: transparent !important;
    }
  }

  .shift-selections {
    padding: 0px 30px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 550;
    border-radius: 10px;
    color: #ffffff;

    &.present {
      background-color: #008500;
    }

    &.present-holiday {
      background-color: #660f8d;
    }

    &.absent {
      background-color: #e11a1a;
    }

    &.absent-black {
      background-color: rgb(74 72 72);
    }

    &.holiday {
      background-color: #757575;
    }

    &.off_day {
      background-color: #a19e9e;
    }

    &.leave {
      background-color: #d970d9;
    }

    &.leave-adherence {
      background-color: #e11a1a;
    }

    &.ontime {
      background-color: #db6034;
    }


    &.unapproved-leave {
      background-color: rgb(211 123 92);
    }

    &.approved_leave_night{
      background-color: rgb(145 30 69);
    }

    &.unapproved_leave_night {
      background-color: rgb(69 69 221);
    }

    &.week-off {
      background-color: rgb(102, 97, 0);
    }

    &.regularization-approved {
      background-color: rgb(143 29 104);
    }

    &.approved-half-day-leave {
      background-color: rgb(159 81 107);
    }

    &.unapproved-half-day-leave {
      background-color: rgb(116, 116, 255);
    }

    &.company-off {
      background-color: rgb(79, 85, 3);
    }

    &.holiday {
      background-color: #757575;
    }

  }

  .shift-table-main-column {
    background-color: #F7F7F7;
    color: #717171;
    border-right: 1px solid #DFDFDF;

    &:before {
      background-color: transparent !important;
    }
  }
}