.dashboard.widget-list-section{
    .dataTable-wrapper.widget-info{
        padding-top: 5px;
    }
    .dataTable-header{
        margin-bottom: 4px;
        background-color: #fff;
        .dataTable-left{
            .input-group{
                input{
                    font-size: 14px;
                    border-radius: 0px;
                }
            }
        }
    }
}
#widget-management{
    .main-content-wrapper{
        .widget-sortable-section{
            h5{
                user-select: none !important; // Disable text selection for this drag handle
            }
            [data-rbd-droppable-context-id="0"]{
                height: 98% !important;
            }
            [data-rbd-drag-handle-context-id="0"] {                   
                user-select: none !important; // Disable text selection for this drag handle                
              }
        }
        .row.dataTable-wrapper{
            .select__control {
                border-radius: 0px;
            }            
        }
    }
}