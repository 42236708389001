.annexure-heading-modal {
    .form-label {
        margin-bottom: 3px !important;
    }

    .annexure-btn-add {
        background-color: #fab616 !important;
        width: 37px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
    }

    .annexure-btn-delete {
        background-color: #eb5757 !important;
        width: 37px;
        margin-left: 5px;
        display: flex;
        justify-content: center;

        .svg_icon {
            color: #fff;
            width: 18px;
            height: 18px;
        }
    }
}

.annexure-heading-modal {
    .global-select.input-border.dotted-select.is-focused {
        &::before {
            height: 100% !important;
        }

        .css-1dyz3mf {
            max-height: unset !important;
        }
    }

    .global-select.input-border.dotted-select {
        .css-1dyz3mf {
            max-height: 30px;
            overflow: hidden;
        }

        .css-1im77uy-control,
        .css-b62m3t-container {
            min-width: 138px !important;
        }

        .css-11sb317-control {
            min-height: 60px;
            margin-left: 3px;
            border: 1px dashed #d2d4da;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .form-check {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 1.5em;
        cursor: pointer;

        .form-check-input {
            border: 1px solid #a1a1a1;
            min-height: 15px;
            padding: 2px 2px 2px 0px;
            cursor: pointer;
        }

        // .form-check-label {
        //   width: 70px;
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }

        .form-check-input:checked {
            background-color: #1f3a6e;
            border-color: #1f3a6e !important;
        }

        .form-check-input:focus {
            box-shadow: none;
        }
    }
}


.annexure-section.dashboard {

    .main-content-wrapper {
        .css-1dyz3mf {
            padding: 2px 8px;
            box-sizing: border-box;
            max-height: 30px;
        }

        .heading-title {
            font-size: 16px;
            color: #000;
            font-weight: 600
        }

        .calculated-row {
            margin-top: 10px;
            column-gap: 20px;
        }

        select {
            min-height: 40px;
            margin-left: 3px;
            border: 1px dashed #d2d4da;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            outline: none !important;

            option {
                font-size: 16px;
            }

            &:hover {
                border-color: hsl(0, 0%, 70%);
            }
        }

        .btn.btn-addnew {
            background-color: #fbb616;
            border-color: #fbb514;
            padding: 4px 10px;
            display: flex;
            justify-content: space-between;
            gap: 8px;
            height: 38px;
            min-width: 102px;
            color: #000;
            font-size: 14px;
            align-items: center;

            img {
                height: 15px;
                margin-top: -3px;
            }
        }

        .input-border.dotted-select {
            position: relative !important;

            &::before {
                position: absolute;
                content: "";
                height: 40px;
                width: 3px;
                background-color: #698ab0;
                left: 0;
                top: 0;
            }
        }

        .input-border.dotted-select.fixed-percentage {

            .css-1im77uy-control,
            .css-b62m3t-container {
                min-width: 138px !important;
            }

            .css-11sb317-control {
                min-height: 40px;
                margin-left: 3px;
                border: 1px dashed #d2d4da;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            // &::before{
            //     z-index: 10;                
            // }
        }

        .input-border.dotted-select.is-focused {
            &::before {
                height: 100% !important;
            }

            .css-1dyz3mf {
                max-height: unset !important;
            }
        }

        .particular-row {
            display: flex;
            // align-items: center;
            column-gap: 15px;
        }
    }
}

.add-payroll-section {
    .main-content-wrapper {
        h4 {
            font-size: 20px;
            color: #000;
            padding-bottom: 5px;
        }

        /* Common table styling for equal width for "Monthly," "Yearly," and "Action" columns */
        .custom-table th:nth-child(2),
        .custom-table td:nth-child(2),
        .custom-table th:nth-child(3),
        .custom-table td:nth-child(3),
        .custom-table th:nth-child(4),
        .custom-table td:nth-child(4) {
            width: 20%;
            /* Equal width for Monthly, Yearly, and Action columns */
        }

        /* Earnings, Deductions, Contributions  table - Set Particulars column width */
        .earnings-table th:nth-child(1),
        .earnings-table td:nth-child(1),
        .deductions-table th:nth-child(1),
        .deductions-table td:nth-child(1),
        .contributions-table th:nth-child(1),
        .contributions-table td:nth-child(1) {
            width: 25%;
        }

        table {
            thead {
                tr {
                    th {
                        color: #000;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        color: #363636;
                        font-size: 14px;
                        overflow: hidden;

                        button.delete-row {
                            background-color: #f5f7fa;
                            border-color: #f5f7fa;

                            svg {
                                fill: #212121;
                            }

                            &:hover {
                                background-color: #f5f7fa;
                                border-color: #f5f7fa;
                            }
                        }

                        button.add-row {
                            align-items: center;
                            background-color: #fbb616;
                            border-color: #fbb514;
                            color: #000;
                            display: flex;
                            font-size: 14px;
                            gap: 8px;
                            height: 38px;
                            justify-content: space-between;
                            min-width: 102px;
                            padding: 4px 10px;

                            img {
                                height: 15px;
                                margin-top: -3px;
                            }
                        }
                    }
                }
            }

            .input-border {
                position: relative;

                &::before {
                    background-color: #698ab0;
                    content: "";
                    height: 40px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 3px;
                }
            }
        }
    }
}


.annexure_heading {
    background-color: #F4F5F9;
    padding: 10px;
    border-radius: 5px;
    margin-top: 12px;
    margin-bottom: 20px;


}

.add-option-particular {
    display: flex;
    align-items: center;
    gap: 12px;

    // cursor: pointer;
    .form-check {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 15px 0px;
        cursor: pointer;

        .form-check-input {
            border: 1px solid #a1a1a1 !important;
            min-height: 15px;
            padding: 2px 2px 2px 0px;
            cursor: pointer;
        }

        .form-check-input:checked {
            background-color: #1f3a6e;
            border-color: #1f3a6e !important;
        }

        .form-check-input:focus {
            box-shadow: none;
        }
    }

    p {
        font-size: 14px;
        color: #717171;

        span {
            cursor: pointer;
        }

        .add_icon {
            img {
                width: 15px;
                margin-right: 12px;
                margin-top: -4px;
            }
        }

        .text-bold {
            color: #013979;
        }
    }
}

.delete-wrapper-particular {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: #fff9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-top: 10px;
}

.annuxure_label {
    font-size: 14px;
    font-weight: 700;
    color: #000;
}

.sum_wrapper_annexure {
    display: flex;
    align-items: center;
    gap: 12px;

    .form-check {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 15px 0px;
        cursor: pointer;

        .form-check-input {
            border: 1px solid #a1a1a1 !important;
            min-height: 15px;
            padding: 2px 2px 2px 0px;
            cursor: pointer;
        }

        .form-check-input:checked {
            background-color: #1f3a6e;
            border-color: #1f3a6e !important;
        }

        .form-check-input:focus {
            box-shadow: none;
        }
    }

    p {
        font-size: 14px;
        color: #717171;

        span {
            cursor: pointer;
        }

        .add_icon {
            img {
                width: 15px;
                margin-right: 12px;
                margin-top: -4px;
            }
        }

        .text-bold {
            color: #013979;
        }
    }
}

// .calculated-section{
//     display: flex;
//     column-gap: 15px;
// }