.header-section {
  box-shadow: 0px 0px 9px #e5e5e5;
  z-index: 9;
  position: fixed;
  width: 100%;
  height: 61px;
  background: #fff;
  .navbar-brand {
    width: 80px;
  }
  .offcanvas-body {
    display: block !important;
    .navbar-nav {
      display: flex;
      justify-content: space-between;
      .nav-link{
        padding: 5px 0px;
      }
      .icon {
        width: 15px;
        height: 15px;
        object-fit: contain;
      }
      .searchBar {
        @media (min-width: 1200px) {
          margin-left: 280px;
        }
      }

      input {
        border: 1px solid #f0f3f5;
        &:focus {
          box-shadow: none;
        }
      }
      .input-group-text {
        background-color: #fdb712;
      }
      .nav-items {
        display: flex;
        align-items: center;
        gap: 20px;
        .theme {
          display: flex;
          gap: 10px;
          .bright-icon {
            box-shadow: 0px 0px 10px #d7d3d3;
            padding: 2px 15px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .bright-select {
          }
        }
        .setting-notify {
          display: flex;
          gap: 10px;
          .icon-wrapper {
            width: 30px;
            height: 30px;
            background-color: #f6f7f9;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .noti {
            position: relative;
            .count-wrapper {
              position: absolute;
              top: -7px;
              right: 0;
              width: 15px;
              height: 15px;
              background: red;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50px;
              span {
                font-size: 10px;
                color: #fff;
              }
            }
            .MuiButtonBase-root {
              margin-left: 0 !important;
            }
          }
        }

        .admin-details {
          display: flex;
          align-items: center;
          background-color: #f6f7f9;
          border-radius: 50px;
          padding: 0px 10px;
          gap: 30px;
          .profile {
            display: flex;
            align-items: center;
            gap: 10px;
            .profile-wrapper {
            }
            img {
              width: 30px;
              height: 30px;
              border-radius: 50px;
              object-fit: cover;
              object-position: top;
            }
            .title-name {
              font-size: 12px;
              color: #000;
              line-height: 1.1;
            }
          }
          .date-wrapper {
            span {
              font-size: 12px;
              color: #000;
            }
          }
        }
      }
    }
  }
}

.noti-popup {
  .noti-title {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 20px;
    margin: 0;
    background-color: #F4F5F9;
    .close_icon {
      width: 15px;
      color: #000;
      cursor: pointer;
    }
  }
  .noti-content {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0px;
    .img-wrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E5EBF1;
        border-radius: 50px;
        margin-right: 10px;
    }
    .noti-details {
      .name {
        font-size: 14px;
        margin: 0;
        color: #000;
      }
      .description {
        font-size: 14px;
        margin: 0;
        color: #000;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        max-width: 280px;
        white-space: pre-line;
      }
      .date-wrapper {
        font-size: 14px;
        margin: 0;
        color: #8c9097;
        .date{
            margin-bottom: 0;
        }
      }
      
    }
    .single_close_icon{
        color: #8C9097;
        width: 14px;
      }
  }
  .noti-read{
    width: 100%;
    justify-content: center;
    align-items: center;
    p{
        margin: 0;
        font-size: 14px;
        padding: 5px 0px; 
        color: #376395;
    }
  }
  .close-wrap{
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .MuiMenuItem-root{
    border-bottom: 1px solid #eee;
  }
}
.MuiList-root{
    padding: 0 !important;
}


.ant-dropdown-menu{
  .ant-dropdown-menu-item{
    .profile-link{
      text-decoration: none;
      display: flex;
      gap: 10px;
      .profile-change-icons{
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
    }
  }
}