.dashboard-grid {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;

  .grid-title-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .grid-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;


      .grid-subtitle {
        font-size: 14px;
        color: #727272;
        font-weight: 400;
      }
    }
    .grid-title-right {
      display: flex;
      align-items: center;
      gap: 10px;

      .card {
        min-width: 155px;
        background-color: #fff;
        border: none;
        font-size: 12px;
        cursor: pointer;
        padding: 0px 10px;

        .p-dropdown {
          min-width: 195px;
          &:hover {
            box-shadow: none;
            border-color: #d1d5db;
          }
        }
        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: none;
          border-color: #d1d5db;
        }

        .p-dropdown-label {
          font-family: "Lato", sans-serif !important;
          color: #727272;
          font-size: 14px;
        }
        .p-inputtext {
          padding: 5px 0.75rem;
          font-size: 14px;
        }
        .p-dropdown-trigger {
          margin-left: 5px;
          .p-icon {
            color: #727272 !important;
            width: 12px;
          }
        }
      }

      .dropdown-calendar {
        position: relative;
        .p-inputtext:enabled:hover {
          border-color: #d1d5db;
        }
        .p-inputtext:enabled:focus {
          box-shadow: none;
          border-color: #d1d5db;
        }
        .calendarIcon {
          left: 20px;
          position: absolute;
          top: 50%;
          z-index: 1;
          margin-top: -10px;
          svg {
            fill: #5c586d;
          }
        }
        .downIcon {
          right: 20px;
          position: absolute;
          top: 50%;
          z-index: 1;
          margin-top: -10px;
          svg {
            fill: #5c586d;
          }
        }
        .p-inputtext {
          padding: 5px 0.75rem 5px 1.8rem;
        }
      }

      .view_link {
        color: #013979;
        font-size: 13px;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  // table starts

  table {
    thead {
      th {
        background-color: #f5f7f9;
        border-bottom-width: 0;
        box-shadow: none;
        font-size: 14px;
        .filterIcon {
          width: 8px;
          margin-left: 3px;
          cursor: pointer;
        }
      }
    }
    td {
      font-size: 13px;
      border-bottom: 1px solid #ecedef;
      .eye-wrwpper {
        background-color: #f5f7f9;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        svg {
          fill: #5c586d;
          width: 13px;
        }
      }
    }
  }

  // table end

  // warrenty starts

  .warrenty-items-wrapper {
    .single-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .img-wrapper {
        img {
          width: 35px;
          height: 35px;
          object-fit: cover;
        }
      }
      .itemDetails {
        .itemTitle {
          font-size: 14px;
          font-weight: 700;
        }
        .itemDescription {
          font-size: 11px;
          color: #797979;
        }
      }
      .eye-wrwpper {
        background-color: #f5f7f9;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        svg {
          fill: #5c586d;
          width: 13px;
        }
      }
    }
    .hrline {
      margin: 12px 0px;
      background-color: #f0f3f5;
      height: 1px;
      opacity: 1;
      border-top: none;
    }
  }

  // warrenty end
}
.upcoming-section.dashboard-grid{
  .grid-title-wrapper{
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    .grid-title-right{
      .dropdown-demo{
        .card{
          padding-left: 0px;
        }
      }
    }
  }
}
.p-dropdown-panel {
  background-color: #ffffff;
  padding: 10px 10px;
  box-shadow: -1px 5px 13px #e5e5e5;
  top: 312px !important;
  .p-dropdown-items-wrapper {
    .p-dropdown-item {
      font-size: 14px;
      padding: 5px 5px !important;
      color: #000;
      font-family: "Lato", sans-serif !important;
    }
  }
}

// date picker
.p-datepicker td > span {
  width: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-datepicker {
  .p-datepicker-next,
  .p-datepicker-prev {
    svg {
      width: 14px;
    }
  }
  .p-datepicker-header {
    .p-datepicker-title {
      font-size: 14px;
      .p-datepicker-month:enabled:hover {
        color: #013979;
      }
      .p-datepicker-year:enabled:hover {
        color: #013979;
      }
    }
  }
  table th {
    font-size: 14px;
  }
  table td > span.p-highlight {
    color: #013979;
  }
}

// date picker

.upcoming-birthday-section {

  .birthday-wrapper {    
    padding-top: 10px;
    background-image: url("../../../../images/home/warrenty/birthday-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    position: relative;
    .MuiDayCalendar-header{      
      margin: auto;
      @media screen and (max-width: 1920px) {
        width: 63%;
      }
      @media screen and (max-width: 1751px) {
        width: 68%;
      }   
      @media screen and (max-width: 1700px) {
        width: 68%;
      }
      @media screen and (max-width: 1661px) {
        width: 71%;
      }
      @media screen and (max-width: 1576px) {
        width: 77%;
      }
      @media screen and (min-width: 1400px) and (max-width: 1488px) {
        width: 87%;
      }
      @media screen and (max-width: 1413px) {
        width: 88%;
      }
      @media screen and (max-width: 1376px) {
        width: 90%;
      }
      @media screen and (max-width: 1336px) {
        width: 95%;
      }
      @media screen and (width: 1480px) {
        width: 84%;
      }
    }
    .upcoming-section.employeeAttendanceTable{
      padding: 0px;
      margin-top: 14px;
      min-height: auto !important;
    }
    .birthday-wrapper-row{
      background-color: #e4e9f0;
      border-radius: 5px;
      padding: 10px 0px;
      position: relative;
      div.img-box{
        position: absolute;
        max-width: 72px;
        top: 30%;
        right: 10%;
        img{
          max-width: 100%;
        }
      }
    }
    .row {
      margin: 0;
    }
    .single-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 10px;
      padding-bottom: 10px;
      .img-wrapper {
        width: 19%;
        img {
          width: 35px;
          height: 35px;
          object-fit: cover;
          border-radius: 50px;
        }
      }
      .detailwrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .itemDetails {
        .itemTitle {
          font-size: 14px;
          font-weight: 700;
        }
        .itemDescription {
          font-size: 12px;
          color: #797979;
        }
      }
      .eye-wrwpper {
        background-color: #f5f7f9;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        img {
          fill: #5c586d;
          width: 13px;
        }
      }
      .cursorDefault{
        cursor: default;
      }
    }
    .prev_icon {
      position: absolute;
      top: -65px;
      right: 20px;
      cursor: pointer;
      border: 1px solid #707070;
      border-radius: 50px;
    }
    .next_icon {
      position: absolute;
      top: -45px;
      right: 20px;
      cursor: pointer;
      border: 1px solid #707070;
      border-radius: 50px;
    }
  }

  .calendar-wrapper {
    background-color: #fff;
    padding: 0;
    padding-top: 20px;
    position: relative;    
    .img-top, .img-bottom{
      position: absolute;
      max-width: 100%;
      img{
        max-width: 100%;
      }
    }
    .img-bottom{
      bottom: 0%;
    }
    .MuiDateCalendar-root {
      width: 100%;
      background-color: #f4f5f9;
      max-height: 290px !important;
    }
    .MuiPickersSlideTransition-root{
      min-height: 213px !important;
    }
    .MuiPickersDay-root {
      width: 30px;
      height: 30px;
      font-family: "Lato", sans-serif !important;
      font-size: 11px;
      font-weight: 600;
    }
    .MuiPickersCalendarHeader-label {
      font-size: 14px;
      font-weight: 700;
      font-family: "Lato", sans-serif !important;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
    .MuiTypography-root{
        font-family: "Lato", sans-serif !important;
        color: #000;
        font-weight: 600;
        font-size: 12px;    
    }
    .MuiPickersDay-today{
        color: #ffff;
        background-color: #013978;
        border: 1px solid #013978;
    }
    // .MuiPickersDay-today{
    //     color: #000;
    //     background-color: #FDB712;
    //     border: 1px solid #FDB712;
    // }
   
  }
}



.min-h404{
    max-height: 404px;
    min-height: 404px;
}