.add-template-section{    
    .template-create-box{
        #editor, #docEditor{
            z-index: 0;
            position: relative;
        }
        .accordion-item{
            border: none;
            margin-bottom: 1px;
            .input-border{
                position: relative;
            }
            .input-border:before{
                position: absolute;
                content: "";
                height: 40px;
                width: 3px;
                background-color: #698ab0;
                left: 0;
                top: 0;
            }
            label{
                font-size: 14px;
                font-weight: 700;
                color: #000;          
                .required{
                    color: red;
                }      
            }
            
            .accordion_button{
                background-color: #4273d3;
                color: #fff;
                border-radius: 10px;
                font-weight: 500;
                position: relative;
                width: 100%;
                text-align: left;
                padding: 12px 20px;
                border-color: #4273d3;
                cursor: default;
            }
            .ck-editor-box{
                .main-container{
                    .parameter-btn{
                        display: flex;
                        justify-content: end;
                        flex-wrap: wrap;
                        margin-bottom: 20px;
                        column-gap: 10px;
                        .input-box{
                            min-width: 158px;
                        }
                        .input-box, .custom-input-box{
                            &:hover{
                                svg{
                                    fill: #fff;
                                }
                            }
                        }
                        button{                            
                            border: none;
                            background: #4273d3;
                            color: white;
                            padding: 8px;
                            font-size: 14px;
                            border-radius: 23px;
                        }
                    }
                    .ck-content.ck-editor__editable{
                        min-height: 300px !important;    
                        max-height: 300px !important;
                        overflow-y: scroll;     
                        ul{
                            li{
                                margin-left: 34px !important;
                            }
                        }              
                    }
                }
            }
        }
    }
}
.headerFooterModal{
    .modal-header{
        .modal-title{
            font-size: 22px;
        }
    }
    .input-border.dotted-select{
       
        input{
            border: 1px dashed #d2d4da;
            border-radius: 0;
            position: relative;
            border-left-color: #fff;
            
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            font-size: 14px;
            color: hsl(0, 0%, 20%);
            
            &:focus{
                box-shadow: unset !important;
            }
            &::before{
                position: absolute;
                content: "";
                height: 40px;
                width: 3px;
                background-color: #698ab0;
                left: 0;
                top: 0;
            }
        }
    }
    .form-label{
        font-size: 14px;
        font-weight: 700;
        color: #000;
    }
    .close-btn{
        background-color: rgb(1, 57, 120);
    }
    .add-btn{
        background-color: rgb(66, 114, 212);
    }
}

.template-list-section{
    .main-content-wrapper{        
        .breadcrum.breadcrum-title{
            font-size: 20px;
            padding-bottom: 5px;
        }
    }
    .dataTable-header{
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        margin-top: -10px;
        .dataTable-right{
            column-gap: 15px;
        }
    }
    .dataTable-left{
        .input-group{
            border: 1px solid #f0f3f5;
            width: auto;
            .form-control{
                border: none;
                font-size: 14px;
                min-width: 200px;
                position: relative;
                z-index: 0;
            }
        }
    }
}
.add-document-section{    
    .add-document-box{
        .dataTable-header{
            background-color: #ffff !important;
            .dataTable-left{
                .input-border{
                    position: relative;
                    &::before{
                        position: absolute;
                        content: "";
                        height: 40px;
                        width: 3px;
                        background-color: #698ab0;
                        left: 0;
                        top: 0;
                    }
                }
                .select__menu {
                    z-index: 10;
                }
                .input-border{
                    width: 180px;
                }
                .input-border.username{
                    width: 200px;;
                }
                .input-border.templateName{
                    width: 300px;
                }
                .input-border.docName{
                    width: 450px;
                }
            }
        }        
    }
    
    .add-variables{
        button{
            margin-right: 10px;
        }
    }
    .ck-editor-box{
        .main-container{
            .ck-content.ck-editor__editable{
                min-height: 300px !important;                        
            }
        }            
    }
}