.document-signing-section {
    .main-content-wrapper {
        display: flex;
        justify-content: center;
    }

    .verification-form-section {
        padding: 20px;
        background: white;
        border-radius: 8px;
        margin-top: 30px;

        h5.title {
            text-align: center;
            font-size: 17px;
        }

        .bottom-box {
            text-align: center;

            div {
                font-size: 14px;
                font-weight: 400;
                column-gap: 3px;

                div {
                    color: blue;
                    cursor: pointer;
                }
            }

            // div{
            //     font-size: 13px;
            //     border-top: 1px solid #e6e7e9;
            //     width: 85%;
            //     margin: auto;
            //     padding-top: 10px;
            // }
        }
    }
}

.dashboard.document-signing-section,
.dashboard.document-view-section {
    margin-left: 0px !important;
}

.dashboard.document-view-section.margin {
    margin-left: 50px !important;
    padding-top: 70px;

    .fs-20 {
        font-size: 20px;
        color: #000;
        padding-bottom: 5px;
    }
}

.dashboard.document-list-section {
    .main-content-wrapper {
        .dataTable-wrapper.template-info {
            .dataTable-header {
                background-color: #ffff !important;
            }
        }

        .dataTable-header {
            .dataTable-left {
                .input-group {
                    // width: 200px !important;
                }
            }
        }
    }
}

.receiver-params-form {
    input.is-invalid {
        border-color: #d2d4da;
    }

    input {
        height: 40px;
        border: 1px dashed #d2d4da;
        border-color: #d2d4da;
        border-radius: 0;
        position: relative;
        border-left-color: #fff;
        margin-left: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 14px;
        color: hsl(0, 0%, 20%);

        &:focus {
            box-shadow: unset !important;
            border-color: #d2d4da;
        }
    }

    .input-border.dotted-select {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            height: 40px;
            width: 3px;
            background-color: #698ab0;
            left: 0;
            top: 0;
        }

    }
}

.add-variables-btn {
    background-color: #4273d3 !important;
    border-color: #779be1 !important;
    color: #fff !important;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 5px !important;
    min-width: 110px !important;
}

.document-agree-form {
    margin-top: 30px;

    .top-box {
        display: flex;
        align-items: flex-start;
        column-gap: 10px;

        input {
            margin-top: 3px;
        }

        p {
            font-size: 12px !important;
        }
    }

    .submitBtn {
        margin-top: 20px;

        button {
            padding: 6px 18px;
        }
    }
}

.dashboard.document-view-section {

    h5.add-variable {
        font-size: 20px;
        color: #000;
        padding-bottom: 5px;
    }

}

.documentConfirModal {
    .modal-footer {

        .btn1,
        .btn2 {
            padding: 6px 20px;
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            min-width: 85px;
            border-radius: 8px;
        }

        .btn1 {
            background-color: #779be1;
            border-color: #779be1;
            border: 1px solid #779be1;

            &:hover {
                background-color: #fff;
                border-color: #90ade7 !important;
                color: #000;
            }
        }

        .btn2 {
            border: 1px solid #fbb514 !important;
            background-color: #fbb514;

            &:hover {
                background-color: #fff;
                border-color: #fbb514 !important;
                color: #000;
            }
        }
    }
}

.add-document-modal {
    .input-border .dotted-select {
        input {
            font-size: 14px;
        }
    }

    .modal-header {
        .modal-title {
            font-size: 22px;
        }
    }

    .body-var,
    .doc-var {

        input {
            height: 40px;
            border: 1px dashed #d2d4da;
            border-radius: 0;
            position: relative;
            border-left-color: #fff;
            margin-left: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            font-size: 14px;
            color: hsl(0, 0%, 20%);

            &:focus {
                box-shadow: unset !important;
            }
        }

        h5 {
            font-size: 14px;
            font-weight: 700;
            color: #000;
        }
    }

    .input-border.dotted-select {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            height: 40px;
            width: 3px;
            background-color: #698ab0;
            left: 0;
            top: 0;
        }
    }
}

.add-document-section {
    .dataTable-wrapper {
        .form-label {
            font-size: 14px;
            font-weight: 700;
            color: #000;
        }
    }
}

.doc-list-btn {
    border-radius: 5px !important;
    font-size: 14px;
    min-width: 89px !important;
    padding: 4px 10px;
}

.document-list-section {
    .main-content-wrapper {
        .breadcrum {
            h4 {
                font-size: 20px;
                padding-bottom: 5px;
            }
        }
    }

    .dataTable-wrapper.document-info {
        .dataTable-header {
            background-color: #ffff !important;

            .input-group {
                border: 1px solid #f0f3f5;
                width: auto;

                input {
                    border: none;
                    font-size: 14px;
                    min-width: 200px;
                    position: relative;
                    z-index: 0;
                }

                .input-group-text {
                    border: none;
                    background-color: #fff;
                }
            }
        }
    }
}