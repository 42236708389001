.interview-view-section.dashboard {
    .exam-results-accordion {
        .label.score-badge {
            background: #008000;
            color: white;
            border-radius: 50%;
            height: 26px;
            width: 26px;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .accordion-header {
            .grid-wrapper {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .single-grid {
                    p {
                        display: inline-block;
                    }

                    .input {
                        font-size: 14px;
                        font-weight: 600;
                        padding-right: 10px;
                        color: #fff !important;
                    }
                }
            }
        }
    }

    .dataTable-wrapper.listing-wrapper-custom {

        .history-main {
            .grid-wrapper {
                display: flex;
                align-items: center;
                column-gap: 12px;
                row-gap: 12px;
                flex-wrap: wrap;

                .single-grid {
                    padding-left: 3px;

                    p {
                        display: inline-block;
                        margin-right: 3px;
                    }

                    .label {
                        color: #fff !important;
                    }

                    .input {
                        font-size: 14px;
                        font-weight: 600;
                        padding-right: 10px;
                        color: #fff !important;
                    }
                }
            }

            .grid_template_answers {
                .card1-bg-color {
                    height: 100%;
                    border-radius: 5px;
                    padding: 12px;
                    margin-right: 20px;
                    border: 1px solid #f5f5f5;
                    background: #f5f5f5;
                    box-shadow: unset;
                }
            }
        }
    }
}