.broadcasting-management {
  .question-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 12px;
    }

    &:focus,
    &:active {
      border-color: none !important;
    }
  }

  .listing-wrapper {
    padding-top: 20px;
  }

  .btn-groups {
    margin-top: 0;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .dataTable-wrapper {
    .dataTable-header {
      background-color: #f4f5f9;
    }



  }
}

.exam-list {
  .completed {
    color: #4aab00 !important;
  }

  .incompleted {
    color: #ffa200 !important;
  }

  .failed {
    color: #ff0000 !important;
  }

  .text-danger,
  .text-success,
  .text-warning {
    min-width: 60px;
  }
}

.exam-list {
  .Low {
    color: #4aab00 !important;
  }

  .Medium {
    color: #ffa200 !important;
  }

  .high {
    color: #ff0000 !important;
  }
}

.add-new-exam {
  .question-sec {
    border-bottom: 1px solid #f0f3f5;
    padding-bottom: 30px;
    padding-top: 30px;

    &:last-child {
      border-bottom-color: transparent;
    }

    ul {
      list-style: none;
      // display: flex;
      gap: 10px;

      li {
        font-size: 18px;
        line-height: 24px;
        color: #000;
        // font-weight: 300;
        letter-spacing: 0.2px;

        .option-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          padding-top: 25px;

          label {
            // font-weight: 400 !important;
            font-weight: bold;
          }

          .form-check-input {
            border-color: #6c7796;
          }

          .form-check-input:checked {
            background-color: #013979;
            border-color: #013979;
          }

          .form-check-input:focus {
            box-shadow: 0 0 0 0.25rem rgb(169 185 207 / 25%);
          }
        }

        .option-textarea {
          padding-top: 25px;

          textarea {
            color: #000;
            font-size: 14px;
            padding: 15px;
          }
        }
      }
    }

    .descriptive-question {
      background-color: #f6f8fa;
      padding: 20px 20px 40px 20px;
      margin-bottom: 20px;
      font-weight: 400 !important;
      line-height: 24px;
      border-radius: 3px;
    }
  }
}

// asset management start

.asset-management {
  .asset-add {
    margin-left: 5px;
    width: 14px;
    height: 13px;
    object-fit: contain;
    cursor: pointer;
  }

  .file-upload-group {
    display: flex;
    gap: 20px;
  }
}

.warrenty {
  .radio-grp {
    .form-check-label {
      font-weight: 400 !important;
    }
  }
}

// category modal starts............

.category-modal {
  .modal-content {
    padding: 30px;
    border-radius: 20px;
  }

  .modal-header {
    border-bottom: none;

    .modal-title {
      font-size: 18px;
      font-weight: 600;

      .subtitle {
        font-weight: 400;
        font-size: 14px;
        padding-top: 10px;
      }
    }

    .btn-close {
      display: none;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-content-cat {
    display: flex;
    gap: 20px;
    padding-bottom: 30px;

    .input-border {
      position: relative;
      width: 70%;

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 3px;
        background-color: #698ab0;
        left: 0;
        top: 0;
      }
    }

    .btn-bulk {
      background-color: #779be1;
      font-size: 14px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 7px;
      justify-content: center;
      color: #fff;

      img {
        width: 15px;
        // margin-top: -5px;
      }
    }
  }

  .modal-content-cat-appriasal {
    display: flex;
    gap: 20px;
    padding-bottom: 0px;

    .input-border {
      position: relative;
      width: 100%;

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 3px;
        background-color: #698ab0;
        left: 0;
        top: 0;
      }
    }

    .btn-bulk {
      background-color: #779be1;
      font-size: 14px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 7px;
      justify-content: center;
      color: #fff;

      img {
        width: 15px;
        // margin-top: -5px;
      }
    }
  }

  .added-cat {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    border: 1px dashed #d2d4da;
    padding: 15px;
    border-radius: 10px;
    min-height: 50px;
    max-height: 117px;
    overflow-y: auto;

    .cat {
      background-color: #f6f8fa;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      padding: 5px 10px;
      border-radius: 5px;
      min-width: 100px;
      font-size: 14px;
    }

    .close_icon {
      cursor: pointer;
    }
  }

  .form-label {
    font-size: 14px;
    font-weight: 600;
  }
}

.category-modal-padd-0 {

  .modal-content {
    padding: 30px;
    border-radius: 20px;
  }

  .modal-header {
    padding: 0;
    border-bottom: none;

    .modal-title {
      font-size: 18px;
      font-weight: 600;

      .subtitle {
        font-weight: 400;
        font-size: 14px;
        padding-top: 10px;
      }
    }

    .btn-close {
      display: none;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-content-cat {
    display: flex;
    gap: 20px;
    padding-bottom: 30px;

    .input-border {
      position: relative;
      width: 70%;

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 3px;
        background-color: #698ab0;
        left: 0;
        top: 0;
      }
    }

    .btn-bulk {
      background-color: #779be1;
      font-size: 14px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 7px;
      justify-content: center;
      color: #fff;

      img {
        width: 15px;
        // margin-top: -5px;
      }
    }
  }

  .modal-content-cat-appriasal {
    display: flex;
    gap: 20px;
    padding-bottom: 0px;

    .input-border {
      position: relative;
      width: 100%;

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 3px;
        background-color: #698ab0;
        left: 0;
        top: 0;
      }
    }

    .btn-bulk {
      background-color: #779be1;
      font-size: 14px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 7px;
      justify-content: center;
      color: #fff;

      img {
        width: 15px;
        // margin-top: -5px;
      }
    }
  }

  .added-cat {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    border: 1px dashed #d2d4da;
    padding: 15px;
    border-radius: 10px;
    min-height: 50px;
    max-height: 117px;
    overflow-y: auto;

    .cat {
      background-color: #f6f8fa;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      padding: 5px 10px;
      border-radius: 5px;
      min-width: 100px;
      font-size: 14px;
    }

    .close_icon {
      cursor: pointer;
    }
  }

  .form-label {
    font-size: 14px;
    font-weight: 600;
  }
}

.resignation-modal {
  .date-picker-group .MuiInputBase-root {
    min-width: 100%;
  }

  .modal-content-cat {
    padding-bottom: 0px;
  }

  .input-border {
    width: 100% !important;
  }
}

// category modal end............

// asset management end

// repository-management start

.repository-management {
  .anticon {
    display: none;
  }

  .ant-upload {
    background-color: #eef1f5;

    &:focus {
      outline: none !important;
    }
  }

  .ant-upload-drag-icon {
    margin-bottom: 0 !important;
  }

  .upload-icon-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 0px;

    img {
      width: 30px;
    }
  }
}

// repository-management end

// hippa-modal strats..................

.hippa-modal {
  .input-border {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      height: 40px;
      width: 3px;
      background-color: #698ab0;
      left: 0;
      top: 0;
    }
  }

  .required {
    color: red;
  }
}

// hippa-modal end..................

// select box multi select styles starts
.css-13qatl9-multiValue {
  background-color: #dce1e7 !important;
  border-radius: 5px !important;
  color: #53545e;
  font-size: 14px;
}

.css-1mbxqx5-MultiValueRemove:hover {
  background-color: #dce1e7 !important;
  color: #013979 !important;
  border-radius: 5px !important;
}

// select box multi select styles end

.MuiCheckbox-colorSecondary.Mui-checked {
  color: red !important;
}

.exam-success {
  .modal-content {
    padding: 0;
    border-radius: 30px;

    .modal-header {
      background-image: url("../../images/TL/success-modal-bg.png");
      width: 100%;
      height: 100px;
      background-repeat: no-repeat;
      background-position: top;
      background-size: auto;
      justify-content: center;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;

      .tick-border {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-top: 90px;
        position: relative;
      }

      .tick-wrapper {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 10px;
        border-radius: 50%;
        box-shadow: 0px 0px 10px #e1d8d8;
        position: absolute;
        top: 10px;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .instructions {
      padding-top: 50px;
      text-align: center;

      h3 {
        font-size: 17px;
        color: #000;
        font-weight: 600;
      }

      p {
        color: #53545e;
        font-size: 14px;
        padding: 10px 40px;
        line-height: 24px;
      }
    }

    .btn-wrapper {
      text-align: center;

      .draft-btn {
        background-color: #013979;
        border-radius: 50px;
        padding: 6px 30px;
        font-size: 14px;
        border-color: #013979;
        margin-top: 20px;
      }
    }

    .modal-body {
      padding-bottom: 50px;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 370px;
    }
  }
}

.save-btn-role {
  &:active {
    border-color: transparent !important;
  }
}