@import url(./components/dashboard.scss);

.dashboard {
  background-color: #f4f5f9;
  margin-left: 50px;
  padding: 30px 0px;
  height: 100%;
  min-height: 100vh;


  .main-content-wrapper {

    // breadcrum section start
    .breadcrum {
      margin-top: 50px;

      .breadcrum-title {
        font-size: 20px;
        color: #000;
        padding-bottom: 5px;
      }

      .breadcrumb-item {
        a {
          font-size: 14px;
          color: #013979 !important;
          text-decoration: none;
        }
      }

      .breadcrumb-item.active {

        font-size: 14px;
        margin-top: 2px;
        color: #53545e;
      }
    }

    // breadcrum section end

    // Top grid slick section start

    .topgrid-section {
      .slick-slide {
        padding-right: 15px;
      }

      .slick-slider {
        .next_icon {
          display: none;
          background: #fff;
          border-radius: 25px;
          box-shadow: 0px 0px 7px #a9a5a5;
          height: 24px;
          width: 24px;
          position: absolute;
          top: 50%;
          margin-top: -12px;
          right: 4px;
          z-index: 1;
          padding: 7px 7px;
          cursor: pointer;
          cursor: pointer;


          @media (max-width: 576px) {
            right: -5px;
            top: 30%;
            left: unset;
          }

          &:hover {

            transition: 0.25s;

            polyline {
              stroke: var(--white);
            }
          }

          &:focus {

            transition: 0.25s;

            polyline {
              stroke: var(--white);
            }
          }
        }

        .prev_icon {
          display: none;
          background: #fff;
          border-radius: 25px;
          box-shadow: 0px 0px 7px #a9a5a5;
          height: 24px;
          width: 24px;
          position: absolute;
          top: 50%;
          margin-top: -12px;
          left: -14px;
          z-index: 1;
          padding: 7px 7px;
          cursor: pointer;

          @media (max-width: 576px) {
            left: 0;
            top: 30%;
          }

          &:hover {

            transition: 0.25s;

            polyline {
              stroke: var(--white);
            }
          }

          &:focus {

            transition: 0.25s;

            polyline {
              stroke: var(--white);
            }
          }
        }

        .slick-dots {
          text-align: end;

          li {
            width: 30px;
            height: 30px;

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              border: 1px solid #0e62a33d;
              border-radius: 6px;
            }
          }

          ul {
            .slick-active {
              width: 30px;
              height: 30px;
              color: #0e62a3;
              background-color: #0e62a33d;
              border-radius: 6px;
            }
          }
        }
      }

      .grids {
        background-color: #fff;
        display: flex !important;
        padding: 15px 20px;
        border-radius: 8px;
        gap: 10px;

        .hide_more {
          display: block;
        }

        .img-wrapper {
          width: 50px;
          height: 50px;
          // background-color: #e5ebf1;
          background-color: #fab514;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;

          img {
            width: 22px;
            height: 20px;
            object-fit: contain;
          }
        }

        .details-wrapper {
          .count {
            font-size: 16px;
            color: #000;
            font-weight: 900;
          }

          .count-color-green {
            color: #0d8e0d;
          }

          .count-color-blue {
            color: #013979;
          }

          .count-color-yellow {
            color: #FDB712;
          }

          .count-color-red {
            color: #3287e8
          }

          .grid-title {
            color: #363636;
            font-size: 14px;
          }
        }

        @media(max-width:1399px) {
          .hide_more {
            display: none;
          }
        }

        @media(max-width:967px) {
          .hide_more {
            display: block;
          }
        }
      }
    }

    // Top grid slick section end
  }
}