.courses-modal {
  .instructions {
    list-style: none;
    padding-left: 20px;

    li {
      font-size: 14px;
      padding-bottom: 10px;
      line-height: 24px;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        background-image: url("../../images/common/caret-left.svg");
        width: 12px;
        height: 12px;
        left: -20px;
        top: 7px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.course-pdf-wrapper {
  .course-title-sec {
    background-color: #f7f8fa;
    display: flex;
    justify-content: space-between;
    padding: 15px 40px;
    border-radius: 7px;

    .left-wrapper {
      position: relative;

      .left-title {
        font-size: 14px;
        font-weight: 600;
        color: #000;
      }

      .left-content {
        color: #53545e;
        font-size: 14px;
      }

      &::before {
        position: absolute;
        content: "";
        background-color: #fab415;
        height: 30px;
        width: 2px;
        left: -10px;
        top: 6px;
      }
    }

    .right-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;

      .btn-join {
        border-color: #779be1;
        background-color: #779be1;
        padding: 4px 17px;
        border-radius: 5px;
        font-size: 14px;
      }

      .btn-course-time {
        border: 1px dashed #d2d4da;
        background-color: #fff;
        color: #000;
        font-size: 14px;
        padding: 4px 17px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: auto;

        svg {
          margin-right: 7px;
        }

        .time-color {
          color: #013979;
          font-weight: 600;
        }
      }
    }
  }
}

.pdf-view-course {
  .is-gapless {
    // display: none;
  }

  .is-2 {
    display: none;
  }

  .pdf-footer {
    .navigators {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;

      .page-size {
        color: #013979;
        font-size: 14px;
        font-weight: 600;
      }

      .right-arrow,
      .left-arrow {
        width: 26px;
        height: 26px;
        border: 1px solid #013979;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;

        svg {
          color: #fab415;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .pdf-wrapper {
    .container {
      // max-height: 800px;
      overflow-y: hidden;
      object-fit: contain;
    }
  }
}

.courseInstructions {
  .instruction-banner {
    background-image: linear-gradient(#c5eaf2, #fafdfe);
    padding: 40px 40px;

    .banner-title {
      font-weight: 600;
      font-size: 18px;
      color: #000;
      padding-bottom: 10px;
    }

    .bannerDescription {
      color: #53545e;
      font-size: 14px;
      padding-bottom: 10px;
    }



    .time-wrapper {
      font-size: 14px;

      .btn-course-time {
        padding-bottom: 20px;

        .time-color {
          color: #013979;
          font-weight: 600;

        }

        svg {
          width: 15px;
          height: 14px;
          margin-top: -3px;
        }
      }

      .btn-join {
        border-color: #779be1;
        background-color: #779be1;
        padding: 4px 17px;
        border-radius: 5px;
        font-size: 14px;
        position: relative;

        &:hover {
          border-color: #779be1;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .banner-img-wrapper {
      text-align: right;

      img {
        width: 240px;
      }
    }
  }

  .instructions-wrapper {
    padding: 20px 40px;
    border: 1px solid #F0F3F5;
    border-radius: 4px;
    margin-top: 10px;

    .instruction-title {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 20px;
    }

    .instructions-lists {
      list-style: none;
      padding-left: 20px;

      li {
        position: relative;
        color: #53545e;
        font-size: 14px;
        padding-bottom: 15px;

        &:before {
          position: absolute;
          content: "";
          background-image: url("../../images/common/caret-left.svg");
          width: 12px;
          height: 12px;
          left: -20px;
          top: 7px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.course-TL {
  .breadcrum {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fixed-timer {
      position: fixed;
      top: 80px;
      right: 280px;
      z-index: 1000;
    }

    .time-wrapper {
      font-size: 14px;
      // background-color: #fff;
      border: 1px dashed #D2D4DA;
      border-radius: 5px;
      background-color: #fbb616;
      border-color: #fbb514;
      padding: 2px 8px;

      .btn-course-time {
        padding: 8px 20px;

        .time-color {
          color: #013979;
          font-weight: 600;

        }

        svg {
          width: 15px;
          height: 14px;
          margin-top: -3px;
        }
      }
    }
  }
}


.leave-approvals {
  .grid-section .grid {
    padding: 5px 15px;
    min-width: 142px;

    .count {
      font-weight: bold;
      font-size: 13px;
    }
  }
}


.decription-view {
  padding-top: 20px;

  .label {
    color: #585964;
    font-size: 14px;
  }

  .input {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }
}